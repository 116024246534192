import React, { useEffect, useRef, useState } from 'react'
import BackArrow from '../../assets/images/BackArrow.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { db, functions } from '../../config/firebase'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../config/firebase'
import { FunnelQuestion } from '../../../payload'

import ScrollToTop from '../../components/ScrollToTop'
import StateLogo from '../../components/StateLogo'
import {
    // getStateNameFromUrl,
    getDomainNameReadable,
    convertToFullName,
    getStateNameFromUrl,
} from '@firmleads/utils'
import PhoneNumberInput from '../../components/PhoneInput'
import TextArea from './TextArea'
import Select from './Select'
import Date from './Date'
import Progress from './Progress'
import FunnelCitySelect from './CitySelect'
import ScrollText from '../../components/ScrollText'
import { timestamp } from '@firmleads/payload-sdk'
// import createInputComponent from './CreateQuestion'

interface FunnelProps {
    updateHide: () => void
    landingPage: string
    landingUrl: string
    city: string
    state: string
    initialFunnel: {
        question: {
            relationTo: 'funnel-questions'
            value: string | FunnelQuestion
        }
        id?: string | null
    }[]
    contactPrompt: string
    directToFunnel?: boolean
    scrollText: string[]
}

const FunnelV3: React.FC<FunnelProps> = ({
    updateHide,
    landingPage,
    landingUrl,
    city,
    state,
    initialFunnel,
    directToFunnel,
    contactPrompt,
    scrollText,
}) => {
    const [formData, setFormData] = useState<any>({})
    const [step, setStep] = useState(0)

    const [name, setName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [formattedPhone, setFormattedPhone] = useState('')
    const [regionCode, setRegionCode] = useState('')

    const [nameError, setNameError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [nameIsValid, setNameIsValid] = useState(false)
    const [phoneIsValid, setPhoneIsValid] = useState(false)
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [pending, setPending] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [partialId, setPartialId] = useState('')
    const [queue, setQueue] = useState<any[]>([])
    const [processing, setProcessing] = useState(false)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    // const [partialRef] = useState(doc(collection(db, 'partial-leads')))
    const [funnel, setFunnelState] = useState(initialFunnel)

    const location = useLocation()
    const navigate = useNavigate()
    const verifyPhone = httpsCallable(functions, 'phone-verifyPhone')
    const verifyEmail = httpsCallable(functions, 'leads-verifyEmail')
    const newLead = httpsCallable(functions, 'leads-newLead')

    const parentRef = useRef(null)

    useEffect(() => {
        verifyPhone({ warmUp: true })
        verifyEmail({ warmUp: true })
        newLead({ warmUp: true })
        db.create({
            collection: 'partial-leads',
            data: {
                city,
                state,
                landingUrl,
                landingPage,
                funnelResponses: [],
                processed: false,
            },
        }).then((value: any) => {
            setPartialId(value.doc.id)
        })
        setFormData({
            city,
            state,
            landingUrl,
            landingPage,
            funnelResponses: [],
        })
    }, [])

    useEffect(() => {
        if (
            directToFunnel &&
            (funnel[0] as any).answer.value?.answer[0].blockType !== 'select'
        ) {
            const citySelect: any = {
                answer: {
                    value: { answer: [{ blockType: 'citySelect' }] },
                },
            }

            setFunnelState([citySelect, ...funnel] as any)
        }
    }, [directToFunnel])

    if (funnel === null || funnel === undefined) {
        return null
    }

    async function updatePartialData(data: any) {
        setFormData(data)
        addToQueue(data)
    }

    // async function savePartialDataToDB(data: any) {
    //     await new Promise((resolve, reject) => {
    //         setTimeout(() => {

    //                 .then(() => resolve({}))
    //                 .catch((error) => reject(error))
    //         }, 200)
    //     })
    // }

    const addToQueue = (item: any) => {
        setQueue((prevQueue) => [...prevQueue, item])
    }

    const processQueue = async () => {
        if (queue.length > 0 && !processing) {
            setProcessing(true)
            console.log('in q', queue)

            const item = queue[0]
            try {
                await db.updateByID({
                    collection: 'partial-leads',
                    id: partialId,
                    data: item,
                })
            } catch (error) {
                await new Promise((resolve) => setTimeout(resolve, 1000))
            }
            setQueue((prevQueue) => prevQueue.slice(1))
            setProcessing(false)
        }
    }
    useEffect(() => {
        processQueue()
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [queue, processing])

    const forwardStep = (validate: () => boolean) => {
        if (validate && !validate()) return

        console.log('step', step)
        if (step + 1 === funnel.length) {
            verifyEmail({ warmUp: true })
            verifyPhone({ warmUp: true })
            newLead({ warmUp: true })
        }
        setStep(step + 1)
        logEvent(analytics, 'forwardStepTo_' + (step + 1))
    }

    function backStep() {
        if (step > 0) {
            setStep(step - 1)
            logEvent(analytics, 'backStepTo_' + (step - 1))
        }
    }

    function returnToLanding() {
        logEvent(analytics, 'backStepLanding')
        updateHide()
    }

    const validateForm = () => {
        return (
            firstName !== '' &&
            phone !== '' &&
            phoneError === '' &&
            email !== '' &&
            emailError === '' &&
            nameError === '' &&
            emailError === ''
        )
    }

    function getAllQueryParams(url: string) {
        const urlParams = new URLSearchParams(new URL(url).search)
        const queryParams: { [key: string]: string } = {}
        urlParams.forEach((value, key) => {
            queryParams[key] = value
        })
        return queryParams
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        setButtonLoading(true)

        db.updateByID({
            collection: 'partial-leads',
            id: partialId,
            data: { submittedAt: timestamp() },
        })

        const phoneValidation = await validatePhone()
        const emailValidation = await validateEmail()
        // validateDescription()
        // validateBudget()

        if (phoneValidation && emailValidation && validateForm()) {
            const trustedFormCertUrlInput = document.getElementsByName(
                'xxTrustedFormCertUrl'
            )[0] as HTMLInputElement | undefined
            const trustedFormCertUrl = trustedFormCertUrlInput?.value || ''

            const lead = {
                ...getAllQueryParams(window.location.href),
                ...formData,
                userAgent: navigator.userAgent,
                trustedformCertUrl: trustedFormCertUrl,
                firstName,
                lastName,
                phone: formattedPhone,
                phoneRegionCode: regionCode,
                email,
                partialId,
            }

            setPending(true)

            newLead(lead).then((result: any) => {
                setPending(false)
                if (result.data.noLawyer) {
                    navigate(`/searching${location.search}`, {
                        state: result.data,
                    })
                } else {
                    navigate(`/request/${result.data.id}${location.search}`, {
                        state: result.data,
                    })
                }
            })
        }
        setButtonLoading(false)
    }

    function updateName(name: string) {
        setName(name)
        updatePartialData({ ...formData, name })
    }

    const validateName = () => {
        const nameSplit = name.split(' ')
        if (nameSplit.length < 2) {
            setNameError('Please provided first and last name')
            setNameIsValid(false)
            return false
        }
        if (nameSplit[0].length < 2) {
            setNameError('Please provided valid input.')
            setNameIsValid(false)
            return false
        }
        if (nameSplit[1].length < 2) {
            setNameError('Please provided your full last name.')
            setNameIsValid(false)
            return false
        }
        setFirstName(nameSplit[0])
        setLastName(nameSplit[1])
        setNameError('')
        setNameIsValid(true)
        return true
    }

    const validatePhone = async () => {
        if (phone.length < 10) {
            setPhoneError('Please provided a valid input.')
            setPhoneIsValid(false)
            return false
        }

        const result: any = await verifyPhone({ phone })
        // console.log('telynx result', result)

        if (!result.data.valid) {
            setPhoneError('Please provide a mobile phone number.')
            setPhoneIsValid(false)
            return false
        }
        setFormattedPhone(result.data.phoneNumber)
        setRegionCode(result.data.regionCode)
        setPhoneError('')
        setPhoneIsValid(true)
        return true
    }

    function updatePhone(phone: string) {
        setPhone(phone)
        updatePartialData({ ...formData, phone })
    }

    const validateEmail = async () => {
        if (email.length < 10) {
            setEmailError('Please provided a valid input.')
            setEmailIsValid(false)
            return false
        }
        const regex = /^[\w\.-]+@[\w\.-]+\.\w+$/

        if (!regex.test(email)) {
            setEmailError('Please provide a properly formatted email.')
            setEmailIsValid(false)
            return false
        }

        const resp: any = await verifyEmail({ email })

        // console.log('email v', resp)

        if (!resp.data.valid) {
            setEmailError('Please provide a valid email')
            setEmailIsValid(false)
            return false
        }
        setEmailError('')
        setEmailIsValid(true)
        return true
    }

    function updateEmail(email: string) {
        setEmail(email)
        updatePartialData({ ...formData, email })
    }

    if (pending) {
        return (
            <div className="flex min-h-screen items-center justify-center lg:pb-[200px] ">
                <div>
                    <StateLogo className="mx-auto h-[75px] lg:mb-[100px] lg:h-[110px]" />
                    <div className="lg:flex">
                        <div className="loader mx-auto mb-[30px] mt-[50px] lg:mx-0 lg:mb-0 lg:mr-[30px] lg:mt-0"></div>
                        <div className="text-center lg:text-left">
                            <p className="font-bold">Dear {firstName},</p>
                            <p>
                                Just a moment while we connect you with an
                                attorney in{' '}
                                <span className="capitalize">
                                    {convertToFullName(state)}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    //console.log(formData)
    //console.log('step', step)

    return (
        <div className="min-h-screen bg-Neutral000 sm:pb-[100px] md:pb-0 lg:bg-MainColor">
            <ScrollToTop />
            <div className="absolute inset-y-0 right-0 hidden h-screen w-1/2 bg-Neutral000 lg:block"></div>
            <div className="relative mx-auto flex max-w-[1280px] flex-col lg:flex-row ">
                <div className="flex w-full flex-col items-start justify-center bg-MainColor px-5 py-2 lg:w-2/5 lg:px-[50px]  ">
                    <StateLogo
                        className="mb-6 mt-3 h-[90px] lg:mb-[50px]"
                        white={true}
                    />
                    <h2 className="font-Leitura text-[24px] text-Neutral000 md:text-[36px]">
                        Join the{' '}
                        <span className="text-SecondColor">7,236+</span>{' '}
                        {getStateNameFromUrl(window.location.hostname)}{' '}
                        Residents who got their legal needs met.
                    </h2>
                </div>
                <div className="mx-auto min-h-[100vh] w-full  max-w-[700px] bg-Neutral000 px-5 pt-[30px] lg:w-3/5 lg:pl-[30px] lg:pt-[40px]">
                    <Progress step={step} funnelSize={funnel.length} />
                    <form onSubmit={handleSubmit}>
                        <input
                            type="hidden"
                            id="gclidField"
                            name="gclidField"
                            value=""
                        />
                        {funnel.map((item, index) => (
                            <React.Fragment key={index}>
                                {step === index && (
                                    <>
                                        {(() => {
                                            const answer = (item! as any)
                                                .question.value?.answer[0]

                                            switch (answer.blockType!) {
                                                case 'textarea':
                                                    return (
                                                        <TextArea
                                                            formData={formData}
                                                            setFormData={
                                                                updatePartialData
                                                            }
                                                            name={answer.name}
                                                            label={answer.label}
                                                            question={
                                                                (item as any)
                                                                    .question
                                                                    .value
                                                                    ?.question
                                                            }
                                                            placeholder={
                                                                answer.placeholder
                                                            }
                                                            required={
                                                                answer.required
                                                            }
                                                            step={step}
                                                            funnelSize={
                                                                funnel.length
                                                            }
                                                            returnToLanding={
                                                                returnToLanding
                                                            }
                                                            backStep={backStep}
                                                            forwardStep={
                                                                forwardStep
                                                            }
                                                        />
                                                    )
                                                case 'select':
                                                    return (
                                                        <Select
                                                            formData={formData}
                                                            setFormData={
                                                                updatePartialData
                                                            }
                                                            name={answer.name}
                                                            subnote={
                                                                answer.subnote
                                                            }
                                                            label={answer.label}
                                                            question={
                                                                (item as any)
                                                                    .question
                                                                    .value
                                                                    ?.question
                                                            }
                                                            options={
                                                                answer.options
                                                            }
                                                            required={
                                                                answer.required
                                                            }
                                                            step={step}
                                                            funnelSize={
                                                                funnel.length
                                                            }
                                                            returnToLanding={
                                                                returnToLanding
                                                            }
                                                            backStep={backStep}
                                                            forwardStep={
                                                                forwardStep
                                                            }
                                                        />
                                                    )
                                                case 'date':
                                                    return (
                                                        <Date
                                                            formData={formData}
                                                            setFormData={
                                                                updatePartialData
                                                            }
                                                            name={answer.name}
                                                            subnote={
                                                                answer.subnote
                                                            }
                                                            label={answer.label}
                                                            question={
                                                                (item as any)
                                                                    .question
                                                                    .value
                                                                    ?.question
                                                            }
                                                            options={
                                                                answer.options
                                                            }
                                                            required={
                                                                answer.required
                                                            }
                                                            step={step}
                                                            funnelSize={
                                                                funnel.length
                                                            }
                                                            returnToLanding={
                                                                returnToLanding
                                                            }
                                                            backStep={backStep}
                                                            forwardStep={
                                                                forwardStep
                                                            }
                                                        />
                                                    )
                                                case 'citySelect':
                                                    return (
                                                        <FunnelCitySelect
                                                            formData={formData}
                                                            setFormData={
                                                                updatePartialData
                                                            }
                                                            required={true}
                                                            step={step}
                                                            funnelSize={
                                                                funnel.length
                                                            }
                                                            returnToLanding={
                                                                returnToLanding
                                                            }
                                                            backStep={backStep}
                                                            forwardStep={
                                                                forwardStep
                                                            }
                                                            directToFunnel={
                                                                directToFunnel
                                                            }
                                                        />
                                                    )
                                                // Add more cases for other component types
                                                default:
                                                    return null
                                            }
                                        })()}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                        {/* {step === 0 && (
                            <div>
                               
                            </div>
                        )}
                        {step === 1 && (
                            <div>
                                
                            </div>
                        )} */}
                        {step === funnel.length && (
                            <div ref={parentRef}>
                                <h2 className="font-Leitura max-w-[670px] pt-[30px] text-[24px] text-Black100 md:text-[36px]">
                                    {contactPrompt}
                                </h2>
                                {scrollText && scrollText.length > 0 && (
                                    <ScrollText
                                        content={scrollText}
                                        parentRef={parentRef}
                                    />
                                )}
                                <div className=" mb-[10px] lg:mb-5 ">
                                    <input
                                        placeholder="Name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => {
                                            updateName(e.target.value)
                                        }}
                                        onBlur={validateName}
                                        className={`${
                                            nameIsValid
                                                ? 'is-valid'
                                                : 'border-[1px] border-Neutral300'
                                        } mb-1 w-full rounded-[12px] px-5 py-[16px] text-Black100`}
                                    />
                                    <span
                                        className={
                                            (nameError !== ''
                                                ? 'block'
                                                : 'hidden') +
                                            ' w-full pl-[10px] pt-[8px] text-Red600 lg:p-0'
                                        }
                                    >
                                        {nameError}
                                    </span>
                                </div>

                                <div className="mb-[10px] lg:mb-5">
                                    <PhoneNumberInput
                                        value={phone}
                                        setValue={updatePhone}
                                        onBlur={validatePhone}
                                        className={`${
                                            phoneIsValid
                                                ? 'is-valid'
                                                : 'border-[1px] border-Neutral300'
                                        } mb-1 w-full rounded-[12px] px-5 py-[16px] text-Black100`}
                                    />

                                    <span
                                        className={
                                            (phoneError !== ''
                                                ? 'block'
                                                : 'hidden') +
                                            ' w-full pl-[10px] pt-[8px] text-Red600 lg:p-0'
                                        }
                                    >
                                        {phoneError}
                                    </span>
                                </div>
                                <input
                                    placeholder="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => {
                                        updateEmail(e.target.value)
                                    }}
                                    onBlur={validateEmail}
                                    className={`${
                                        emailIsValid
                                            ? 'is-valid'
                                            : 'border-[1px] border-Neutral300'
                                    } mb-1 w-full rounded-[12px] px-5 py-[16px] text-Black100`}
                                />
                                <span
                                    className={
                                        (emailError !== ''
                                            ? 'block'
                                            : 'hidden') + ' w-full text-Red600'
                                    }
                                >
                                    {emailError}
                                </span>
                                <p className="mt-5 text-[14px] text-Black400">
                                    By clicking "Connect Me To A Lawyer!", you
                                    agree to be contacted about your legal
                                    request via text message from{' '}
                                    {getDomainNameReadable(
                                        window.location.hostname
                                    )}
                                    . Msg & data rates may apply. Msg frequency
                                    varies. Unsubscribe ay anytime by replying
                                    STOP.{' '}
                                    <Link className="underline" to="/privacy">
                                        Privacy Policy
                                    </Link>{' '}
                                    &{' '}
                                    <Link className="underline" to="./terms">
                                        Terms
                                    </Link>
                                </p>
                            </div>
                        )}

                        <div
                            className={
                                (step === funnel.length ? 'flex' : 'hidden') +
                                ' mt-[30px] justify-between'
                            }
                        >
                            {buttonLoading ? (
                                <div className="loader order-1 mx-auto mb-[30px] mt-[50px] lg:order-2 lg:mx-0 lg:mb-0 lg:mr-[30px] lg:mt-0"></div>
                            ) : (
                                <button
                                    type="submit"
                                    className="font-Arial order-1 flex  justify-center rounded-[8px] bg-SecondColor px-[30px] py-[16px] text-[16px] font-bold text-Neutral000 lg:order-2 lg:mb-0 lg:w-auto lg:px-[30px] lg:text-[18px]"
                                >
                                    <p>Connect Me To A Lawyer!</p>

                                    <svg
                                        width="10"
                                        height="15"
                                        viewBox="0 0 16 24"
                                        className="ml-2 mt-[4px] md:mt-1"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.47158 5.42625C4.47158 3.48 6.05408 1.8975 8.00408 1.8975C9.95033 1.8975 11.5328 3.48 11.5328 5.42625V8.58375H13.5391V5.53125C13.5391 2.4825 11.0566 0 8.00033 0C4.94783 0 2.46533 2.4825 2.46533 5.53125V8.58375H4.46783V5.42625H4.47158Z"
                                            fill="#ffffff"
                                        />
                                        <path
                                            d="M14.165 9.13123H1.84252C0.890019 9.13123 0.11377 9.90748 0.11377 10.86L0.22252 16.3687C0.30502 18.8137 1.46377 21.0937 3.31627 22.4625C4.67752 23.4675 6.29377 24 7.99627 24C9.70252 24 11.3225 23.4675 12.68 22.4625C14.5325 21.09 15.6913 18.81 15.7738 16.3612L15.8863 10.8487C15.8938 9.89998 15.1175 9.13123 14.165 9.13123ZM8.00002 19.815C6.00502 19.815 4.38127 18.195 4.38127 16.2C4.38127 14.205 6.00502 12.5812 8.00002 12.5812C9.99502 12.5812 11.6188 14.205 11.6188 16.2C11.6188 18.195 9.99502 19.815 8.00002 19.815Z"
                                            fill="#ffffff"
                                        />
                                        <path
                                            d="M9.55986 14.2988C9.36861 14.2988 9.19236 14.3738 9.05736 14.5051L7.48611 16.0763L6.94236 15.5326C6.80736 15.3976 6.63111 15.3263 6.43986 15.3263C6.25236 15.3263 6.07236 15.4013 5.94111 15.5326C5.80611 15.6676 5.73486 15.8438 5.73486 16.0351C5.73486 16.2263 5.80986 16.4026 5.94111 16.5376L6.98736 17.5838C7.12236 17.7188 7.29861 17.7901 7.48986 17.7901C7.68111 17.7901 7.85736 17.7151 7.98861 17.5838L10.0624 15.5101C10.1974 15.3751 10.2686 15.1988 10.2686 15.0076C10.2686 14.8163 10.1936 14.6401 10.0624 14.5088C9.92736 14.3738 9.75111 14.2988 9.55986 14.2988Z"
                                            fill="#ffffff"
                                        />
                                    </svg>
                                </button>
                            )}
                            <button
                                type="button"
                                onClick={backStep}
                                className="font-Arial flex items-center justify-center rounded-[8px] border-[2px]  border-Neutral300 px-[16px] py-[16px] text-[18px] font-bold text-Black400 lg:px-[30px]"
                            >
                                <img
                                    className="lg:mr-[10px]"
                                    src={BackArrow}
                                    alt="back arrow"
                                />
                                <span className="hidden lg:block">Back</span>
                            </button>
                            {/* <button
                                type="button"
                                onClick={backStep}
                                className="font-Arial order-2 flex w-full items-center justify-center rounded-[8px] border-[2px] border-Neutral300 px-[16px] py-[16px] text-[18px] font-bold text-Black400 lg:order-1 lg:w-auto lg:px-[30px]"
                            >
                                <img
                                    className="lg:mr-[10px]"
                                    src={BackArrow}
                                    alt="back arrow"
                                />
                                <span className="">Back</span>
                            </button> */}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FunnelV3
