import { Disclosure } from '@headlessui/react'
import React from 'react'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../config/firebase'
// import WoodBackground from '../../../components/WoodBackground'
import bgImg from '../../../assets/images/BG.svg'
import { getStateNameFromUrl } from '@firmleads/utils'
import ButtonCTA from '../../../components/ButtonCTA'
import { useLocation } from 'react-router-dom'

let content = [
    [
        {
            id: 1,
            question: 'How quickly will I be able to talk to a lawyer?',
            answer: 'You will be immediately connected with a lawyer as soon as you submit your request. Depending on the time of day, you may be able to talk to a lawyer within a few minutes.',
        },
        {
            id: 2,
            question: 'Will I receive a free consultation?',
            answer: `Yes, you will receive a free consultation with a local ${getStateNameFromUrl(window.location.hostname)} Lawyer that serves the city you need help in.`,
        },
        {
            id: 3,
            question: 'How much does a lawyer typically charge?',
            answer: ' If your case qualifies for an unbundled legal service, the starting cost will typically be between $500-$1500. If you need full representation, this will cost more. ',
        },
        {
            id: 4,
            question: 'What is an unbundled legal service?',
            answer: 'An unbundled legal service, is when you hire a lawyer to help you with only parts of your case. Since you are paying for less hours of legal help, and you will NOT be required to pay such a large retainer up front, unbundled legal services are much more affordable.',
        },
        {
            id: 5,
            question: 'Will the lawyer offer payment plans?',
            answer: 'Most of our lawyers offer payment plans. If you cannot afford the starting fee, just ask your lawyer if they can break it up into a few payments and they will usually work with you.',
        },
        // {
        //     id: 3,
        //     question: 'What if I don’t like my lawyer?',
        //     answer:
        //         `Working with a ${getStateNameFromUrl(window.location.hostname)} lawyer that provides unbundled service could save you thousands of dollars. Since the consultation is free, and there is no obligation to hire the lawyer we connect you with, give it a try and find out how much you’ll save on legal fees!`,
        // },
        // {
        //     id: 4,
        //     question: 'Can I stay at my own place?',
        //     answer:
        //         "Of course, it's your place! We do not limit homeowner use of vacation rentals.",
        // },
    ],
]

const Recent1 = [
    [
        {
            id: 1,
            question: 'Will I receive a free consultation?',
            answer: `Yes, you will receive a free consultation with a local ${getStateNameFromUrl(window.location.hostname)} Lawyer that serves the city you need help in.`,
        },
        {
            id: 5,
            question: 'How much does a lawyer typically charge?',
            answer: ' If your case qualifies for an unbundled legal service, the starting cost will typically be between $500-$1500. If you need full representation, this will cost more. ',
        },
        {
            id: 3,
            question: 'What if I don’t like my lawyer?',
            answer: `Since the consultation is free, and there is no obligation to hire the lawyer we connect you with.`,
        },
        // {
        //     id: 4,
        //     question: 'Can I stay at my own place?',
        //     answer:
        //         "Of course, it's your place! We do not limit homeowner use of vacation rentals.",
        // },
    ],
    [
        {
            id: 5,
            question: 'How quickly will I be able to talk to a lawyer?',
            answer: 'You will be immediately connected with a lawyer as soon as you submit your request. Depending on the time of day, you may be able to talk to a lawyer within a few minutes.',
        },
        {
            id: 6,
            question: 'Will the lawyer offer payment plans?',
            answer: 'Most of our lawyers offer payment plans. If you cannot afford the starting fee, just ask your lawyer if they can break it up into a few payments and they will usually work with you.',
        },
        {
            id: 7,
            question: 'What is an unbundled legal service?',
            answer: 'An unbundled legal service, is when you hire a lawyer to help you with only parts of your case. Since you are paying for less hours of legal help, and you will NOT be required to pay such a large retainer up front, unbundled legal services are much more affordable.',
        },
        // {
        //     id: 8,
        //     question: 'How do I get started?',
        //     answer:
        //         'The fastest way to get started is to give us a call at 415-209-5847 or give us an email at ',
        // },
    ],
]
interface FAQProps {
    updateHide: () => void
    faqs: any[]
    finalCta: any
}

const FAQ: React.FC<FAQProps> = ({ updateHide, faqs, finalCta }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const isFreeConsultation =
        searchParams.get('isFreeConsultation') !== 'false'
    if (!isFreeConsultation) {
        Recent1[0][0].answer = `Most times yes, but some our local ${getStateNameFromUrl(window.location.hostname)} lawyers do not offer free consultations. Requesting a consultation does not commit you to anything.`
    }
    if (faqs.length > 0 && faqs[0].question !== '') {
        content[1] = faqs
    } else {
        content = Recent1
    }
    const logPress = () => {
        logEvent(analytics, 'finalCtaPressed')
    }
    return (
        <div className="px-4 md:px-5">
            <div className="mx-auto mt-[50px] max-w-[1170px] md:mt-[100px]">
                <section id="faq" className="mb-[50px] md:mb-[100px]">
                    <div className="font-Leitura text-center text-[24px] text-Black100 md:text-[36px]">
                        Your Questions Answered
                    </div>
                    <div className="flex flex-wrap justify-center pt-[20px] md:pt-[30px]">
                        {content?.map((item, index) => (
                            <ul key={index}>
                                {item.map((res, index) => (
                                    <div className="pt-[20px]" key={index}>
                                        <div className="font-Arial w-full rounded-lg border-[1px] md:mr-4 md:w-[400px] lg:mr-6">
                                            <Disclosure>
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button className="w-[100%]">
                                                            <div className="flex w-[100%] justify-between text-left font-medium text-Black100 focus:outline-none">
                                                                <p
                                                                    className={`${
                                                                        open
                                                                            ? 'font-bold'
                                                                            : ''
                                                                    } w-[85%] py-[10px] pl-4 text-[16px] md:text-[18px]`}
                                                                >
                                                                    {
                                                                        res?.question
                                                                    }
                                                                </p>
                                                                {!open ? (
                                                                    <div className="flex w-[55px] items-center justify-center bg-Neutral300 text-[30px]">
                                                                        +
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex w-[55px] items-center justify-center text-[30px]">
                                                                        -
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Disclosure.Button>
                                                        <Disclosure.Panel className="px-4 pb-2 pt-4 text-[16px] font-normal leading-6 text-Black100 md:text-[18px]">
                                                            {res?.answer}
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        ))}
                    </div>
                </section>
                {/* <WoodBackground /> */}
                <div className="text-center">
                    <div
                        className="bg- relative mx-auto block h-[304px] w-full max-w-[1170px] rounded-lg bg-MainColor bg-cover bg-center sm:h-[342px]"
                        style={{
                            backgroundImage: `url(${bgImg})`,
                        }}
                    >
                        <div className="absolute inset-0 flex flex-col items-center justify-center p-5">
                            <div className="font-Leitura text-[24px] text-Neutral000 md:text-[36px]">
                                {finalCta.headline}
                            </div>
                            <p className="mt-[10px] max-w-[470px] text-[16px] font-normal text-Neutral000 md:text-[18px]">
                                {finalCta.description}
                            </p>
                            <div className="font-Arial w-full sm:w-fit">
                                <a href="#form">
                                    <ButtonCTA
                                        onClick={logPress}
                                        className="mt-[30px] flex justify-center text-[18px]"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ
