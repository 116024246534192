export const themeMap: any = {
    firmleads: 'red-theme',
    legalhelphawaii: 'green-theme',
    legalhelporegon: 'green-theme',
    legalhelpsouthcarolina: 'gold-theme',
    californialawconnect: 'ca-theme',
    legalhelptennessee: 'gold-theme',
    floridalegalconnect: 'fl-theme',
    legalhelpohio: 'oh-theme',
    newyorklawconnect: 'ny-theme',
    texaslawconnect: 'tx-theme',
    northcarolinalawconnect: 'nc-theme',
    georgialawconnect: 'ga-theme',
}

export const titleMap: any = {
    firmleads: 'FirmLeads',
    legalhelphawaii: 'Hawaii Legal Help',
    legalhelporegon: 'Oregon Legal Help',
    legalhelpsouthcarolina: 'South Carolina Legal Help',
    californialawconnect: 'California Law Connect',
    floridalegalconnect: 'Florida Legal Connect',
    legalhelpohio: 'Ohio Legal Help',
    legalhelptennessee: 'Tennessee Legal Help',
    newyorklawconnect: 'New York Law Connect',
    texaslawconnect: 'Texas Law Connect',
    northcarolinalawconnect: 'North Carolina Law Connect',
    georgialawconnect: 'Georgia Law Connect',
}

export const descriptionMap: any = {
    firmleads: 'Lead Generation & Client Intake for Lawyers',
    legalhelphawaii: 'Helping Hawaii have access to proper legal help.',
    legalhelporegon: 'Helping Oregon have access to proper legal help.',
    californialawconnect:
        'Helping California have access to proper legal help.',
    legalhelpsouthcarolina:
        'Helping South Carolina have access to proper legal help.',
    floridalegalconnect: 'Helping Florida have access to proper legal help.',
    legalhelpohio: 'Helping Ohio have access to proper legal help.',
    legalhelptennessee: 'Helping Tennessee have access to proper legal help.',
    newyorklawconnect: 'Helping New York have access to proper legal help',
    texaslawconnect: 'Helping Texas have access to proper legal help',
    northcarolinalawconnect:
        'Helping North Carolina have access to proper legal help',
    georgialawconnect: 'Helping Georgia have access to proper legal help',
}
