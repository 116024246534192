import React, { useRef } from 'react'
import BannerFormV2 from '../../../components/BannerFormV2'
// import { getArticle } from '../../../components/AddArticle'
import Man from '../../../assets/images/lawyers3.png'
import ScrollText from '../../../components/ScrollText'
import Slider from 'react-slick'
import { SampleNextArrow, SamplePrevArrow } from '../Attorneys'
import FormReview from '../../../components/FormReview/FormReview'

interface FormProps {
    updateHide: () => void
    updateCity: (city: string) => void
    updateState: (state: string) => void
    lawCategory?: string
    benefits?: any
    reviews?: any
    area?: string
    headline2?: string
    scrollText?: string[]
    formHeadline: string
}

const Form: React.FC<FormProps> = ({
    updateHide,
    updateCity,
    updateState,
    lawCategory,
    benefits,
    reviews,
    area,
    headline2,
    scrollText,
    formHeadline,
}) => {
    const parentRef = useRef(null)
    return (
        <section id="form">
            <div className="my-[50px] md:my-[100px]">
                <div className="mb-[50px] md:mb-[100px]">
                    <div className="mx-auto mt-8 flex max-w-screen-xl flex-wrap items-center justify-center gap-x-8 gap-y-5 px-[16px] md:px-6 lg:justify-around lg:px-8">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/bbb_1.png?alt=media&token=18998058-a2ea-4951-a03e-2b0d4e3ed98b"
                            alt="Better Business Bureau"
                            className="h-14 md:h-[95px]"
                        />
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/super_lawyers.png?alt=media&token=8dcd2972-2fcc-4a37-b0f2-637851941163"
                            alt="Super Lawyers"
                            className="h-14 md:h-[95px]"
                        />
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/avvo.png?alt=media&token=b50beafd-4d8f-4cc1-9f55-ffd247abc0fc"
                            alt="Avvo"
                            className="h-14 md:h-[95px]"
                        />
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/bbb_2.png?alt=media&token=c0c67413-1308-406a-b758-0404b5adcbf8"
                            alt="Better Business Bureau"
                            className="h-14 md:h-[95px]"
                        />
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/firmleads-production.appspot.com/o/google.png?alt=media&token=f3008683-f1dd-4e34-8f55-0b9901149682"
                            alt="Google"
                            className="h-14 md:h-[95px]"
                        />
                    </div>
                </div>
                <div className="justify-center md:flex md:px-2">
                    <div className=" mx-auto flex items-center justify-center px-[16px] md:mx-0 md:w-[400px] md:px-0 md:text-left lg:mr-[75px]">
                        <img
                            src={Man}
                            alt="man"
                            loading={'lazy'}
                            className=" rounded-[16px] bg-BgColor"
                        />
                    </div>
                    <div
                        ref={parentRef}
                        className="mx-auto mt-[20px] w-full px-4 md:ml-[15px] md:mr-0 md:mt-0 md:w-[390px] md:px-0 lg:ml-[30px]"
                    >
                        {scrollText && scrollText.length > 0 && (
                            <ScrollText
                                content={scrollText}
                                parentRef={parentRef}
                            />
                        )}
                        <BannerFormV2
                            formHeadline={formHeadline}
                            area={area}
                            updateCity={updateCity}
                            updateState={updateState}
                            updateHide={updateHide}
                        />
                        <h2 className="font-Arial mt-[20px] text-center text-[14px] font-bold text-Black100">
                            {headline2}
                        </h2>
                    </div>
                </div>
                <div className="mx-auto mt-[30px] flex max-w-[850px] flex-col justify-center gap-[20px] px-[16px] sm:flex-row sm:gap-[30px] md:mt-[55px]">
                    <Slider
                        dots={false}
                        infinite={true}
                        speed={500}
                        slidesToShow={2}
                        slidesToScroll={1}
                        nextArrow={<SampleNextArrow />}
                        prevArrow={<SamplePrevArrow />}
                        centerMode={false}
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                },
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    centerMode: true,
                                    arrows: false,
                                },
                            },
                        ]}
                        autoplaySpeed={5000}
                        arrows={true}
                        className="h-full w-full"
                    >
                        {reviews.map((review: any, index: number) => (
                            <FormReview
                                note={review.note}
                                name={review.name}
                                picture={review.picture ?? review.img.url}
                                key={index}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Form
