import React, { useEffect, useState } from 'react'
import PlanDetail from './PlanDetail'
import BannerDetailV4 from './BannerV4'
import NoHugeFees from './NoHugeFees'
import LawOverview from './LawOverview'
import FreeConsultation from './FreeConsultation'
import Stats from './Stats'
import WhyLawyer from './WhyLawyer'
import Reviews from './Reviews'
import AboutUs from './AboutUs'
import FAQ from './FAQ'
import Funnel from '../FunnelV3'
import content from '../../assets/webcopy.json'
import { useParams } from 'react-router-dom'
import Form from './FormV2'
import { LandingPage } from '../../../payload'
import Attorneys from './Attorneys'
import { getDomainName } from '@firmleads/utils'
import { apiUrl } from '../../config/firebase'
// import WhyAffordable from './WhyAffordable'
type Content = { [key: string]: any }

const Landing: React.FC = () => {
    const [hideLanding, setHideLanding] = useState(false)
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [page, setPage] = useState<LandingPage>()

    const locationSplit = window.location.hostname.split('.')
    const landingUrl =
        locationSplit[locationSplit.length - 2] +
        '.' +
        locationSplit[locationSplit.length - 1]

    let subdomain = locationSplit[0]

    console.log('landingUrl', landingUrl)

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            if (event.state && event.state.formOpen) {
                setHideLanding(false)
            }
        }

        window.addEventListener('popstate', handlePopState)

        return () => {
            window.removeEventListener('popstate', handlePopState)
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (
                subdomain === '' ||
                subdomain === undefined ||
                subdomain === null
            )
                return

            console.log('subdomain', subdomain)
            try {
                const response = await fetch(
                    `${apiUrl}/api/landing-pages?where[subdomain.name][equals]=${subdomain}`
                )
                const data = await response.json()
                setPage(data.docs[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [subdomain])

    let pageContent = (content as Content)[subdomain || 'auto']

    if (pageContent === undefined) {
        subdomain = 'family'
        pageContent = (content as Content)['family']
    }

    const updateCity = (city: string) => {
        setCity(city)
    }

    const updateState = (state: string) => {
        setState(state)
    }
    const updateHideLanding = () => {
        if (!hideLanding) {
            window.history.pushState({ formOpen: true }, '')
        } else {
            window.history.go(-1)
        }
        setHideLanding(!hideLanding)
    }

    let { lawArea, location } = useParams()
    // console.log('location---', location)
    // console.log('page---', page)

    // const renderComponent = (block: any) => {
    //     switch (block.blockType) {
    //         case 'alder':
    //             return (
    //                 <BannerDetailV4
    //                     lawCategory={block.lawCategory}
    //                     video={block.backgroundVideo.url}
    //                     img={block.backgroundImgUrl}
    //                     lawArea={lawArea}
    //                     location={location}
    //                     connectBool={false}
    //                     headline={block.headline}
    //                 />
    //             )
    //         case 'banana':
    //             return (
    //                 <Form
    //                     updateHide={updateHideLanding}
    //                     updateCity={updateCity}
    //                     updateState={updateState}
    //                     lawCategory={block.lawCategory}
    //                     benefits={[
    //                         block.benefit1,
    //                         block.benefit2,
    //                         block.benefit3,
    //                     ]}
    //                     reviews={block.reviews}
    //                     area={subdomain}
    //                     headline2={pageContent.headline2}
    //                 />
    //             )
    //         case 'cabbage':
    //             return (
    //                 <PlanDetail
    //                     updateHide={updateHideLanding}
    //                     lawCategory={block.lawCategory}
    //                 />
    //             )
    //         case 'drewberry':
    //             return (
    //                 <LawOverview
    //                     updateHide={updateHideLanding}
    //                     content={block}
    //                 />
    //             )
    //         case 'eytelia':
    //             return <Stats />
    //         case 'fennel':
    //             return <NoHugeFees />
    //         case 'garlic':
    //             return <Reviews updateHide={updateHideLanding} />
    //         case 'hellebore':
    //             return <FreeConsultation />
    //         case 'why-affordable':
    //             return <WhyAffordable />
    //         case 'inkberry':
    //             return <WhyLawyer content={block} lawArea={lawArea!} />
    //         case 'jasmine':
    //             return <AboutUs updateHide={updateHideLanding} />
    //         case 'kudzu':
    //             return <FAQ updateHide={updateHideLanding} faqs={block} />
    //         default:
    //             return null
    //     }
    // }

    if (hideLanding) {
        return (
            <div className={hideLanding ? 'block' : 'hidden'}>
                <Funnel
                    updateHide={updateHideLanding}
                    landingPage={subdomain || 'auto'}
                    landingUrl={landingUrl}
                    city={city}
                    state={state}
                    initialFunnel={page?.variants![0]!.funnel!}
                    contactPrompt={page?.variants![0]!.contactPrompt!}
                    scrollText={pageContent.scrollText}
                />
            </div>
        )
    }

    // return (
    //     <>
    //         {page?.variants?.[0]?.layout?.map((block, index) => (
    //             <React.Fragment key={index}>
    //                 {renderComponent(block)}
    //             </React.Fragment>
    //         ))}
    //     </>
    // )

    return (
        <>
            <BannerDetailV4
                callHeader={pageContent.callHeader}
                phone={pageContent.phone}
                displayPhone={pageContent.displayPhone}
                lawCategory={pageContent.name}
                video={pageContent.background.video}
                img={pageContent.background.img}
                lawArea={lawArea}
                location={location}
                connectBool={false}
                headline={pageContent.headline}
            />
            <Form
                updateHide={updateHideLanding}
                updateCity={updateCity}
                updateState={updateState}
                lawCategory={pageContent.name}
                benefits={pageContent.benefits}
                reviews={pageContent.reviews}
                area={subdomain}
                headline2={pageContent.headline2}
                scrollText={pageContent.scrollText}
                formHeadline={pageContent.formHeadline}
            />
            <PlanDetail
                updateHide={updateHideLanding}
                lawCategory={pageContent.name}
                content={pageContent.steps}
            />
            <LawOverview
                updateHide={updateHideLanding}
                content={pageContent.lawOverview}
            />
            <Stats avgStartCost={pageContent.avgStartCost} />
            <NoHugeFees content={pageContent.fees} />
            <Reviews
                updateHide={updateHideLanding}
                reviews={pageContent.reviewsLower}
            />
            <FreeConsultation content={pageContent.consultation} />
            <WhyLawyer
                content={pageContent.whyLawyer}
                lawArea={pageContent.name}
            />
            <AboutUs
                updateHide={updateHideLanding}
                content={pageContent.aboutUs}
            />
            {subdomain === 'auto' &&
                getDomainName(window.location.hostname) ===
                    'californialawconnect' && <Attorneys />}
            <FAQ
                updateHide={updateHideLanding}
                faqs={pageContent.faq}
                finalCta={pageContent.finalCta}
            />
        </>
    )
}

export default Landing
