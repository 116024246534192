import React, { useState } from 'react'

interface LawyerReviewProps {
    review: any
}

const LawyerReview: React.FC<LawyerReviewProps> = ({ review }) => {
    const [showFullReview, setShowFullReview] = useState(false)

    const reviewLength = 75

    const toggleReview = () => {
        setShowFullReview(!showFullReview)
    }

    const shortenedText =
        review.note.split(' ').length < reviewLength
            ? review.note
            : showFullReview
              ? review.note
              : `${review.note.split(' ').slice(0, reviewLength).join(' ')} ... `
    return (
        <div className="mt-[30px] flex ">
            <img
                className="mr-[10px] h-[40px] w-[40px]"
                src={review.picture}
                alt="review"
            />
            <div>
                <p className="font-Arial mb-[10px] text-justify text-[16px] font-bold text-Black100 md:text-[18px]">
                    {review.name}
                </p>
                <p className="font-Arial text-justify text-[14px] text-Black400 md:text-[16px]">
                    {shortenedText}
                    {review.note.split(' ').length > reviewLength && (
                        <button
                            className="text-Black100 underline"
                            onClick={toggleReview}
                        >
                            {' '}
                            {showFullReview ? 'Less' : 'More'}
                        </button>
                    )}
                </p>
            </div>
        </div>
    )
}

export default LawyerReview
