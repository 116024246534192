import { Link, useLocation } from 'react-router-dom'
import NoFoundPic from '../../assets/images/NoFoundPic.svg'

const NoFound = () => {
    const location = useLocation()

    const { state } = location

    return (
        <div
            className="items-center justify-center p-5 lg:pb-[200px]"
            style={{ minHeight: 'calc(100vh - 453px)' }}
        >
            <img
                src={NoFoundPic}
                alt="No Lawyer Found"
                className="mx-auto my-[50px] h-[227px] lg:my-[100px] lg:h-[315px]"
            />

            <h1 className="font-Leitura mb-6 text-center text-4xl">
                No Lawyer Found
            </h1>

            <div className="font-Arial mx-auto max-w-[400px] text-center text-Black400">
                <p>
                    We're sorry, we don't currently have a lawyer specializing
                    in {state ? state.lawArea : 'family'} law in{' '}
                    {state ? state.state : 'Hawaii'}. We are always expanding
                    our network. Will notify you if we partner with a lawyer
                    that can service you.
                </p>
                <Link to="/">
                    <button className=" mt-[30px] h-[60px] w-full rounded-lg bg-MainColor px-[29px] py-[16px] text-[16px] font-bold text-white lg:w-[320px]">
                        Go Back to Home
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default NoFound
