import React from 'react'
// import { logEvent } from 'firebase/analytics'
// import { analytics } from '../../../config/firebase'
import Review from './Review'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { getDomainNameReadable } from '@firmleads/utils'
import ButtonCTA from '../../../components/ButtonCTA'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../../config/firebase'
import { SampleNextArrow, SamplePrevArrow } from '../Attorneys'

interface ReviewsProps {
    updateHide: () => void
    reviews: any[]
}

const Reviews: React.FC<ReviewsProps> = ({ updateHide, reviews }) => {
    const logPress = () => {
        logEvent(analytics, 'reviews_cta_pressed')
    }

    return (
        <section id="reviews">
            <div className="border border-transparent bg-BgColor pt-[50px] lg:pt-[100px]">
                <div className="mx-auto mb-[50px] w-full max-w-[1170px] md:mb-[100px] md:w-[90%] xl:w-[auto]">
                    <div className="md:flex md:items-center">
                        <div className="mb-[30px] flex w-full flex-col px-4 md:flex-row md:px-5">
                            <h2 className="font-Leitura mx-auto mb-[15px] text-center text-[24px] text-Black100 md:mb-0 md:w-[50%] md:text-start md:text-[36px]">
                                What Others Say About{' '}
                                {getDomainNameReadable(
                                    window.location.hostname
                                )}{' '}
                            </h2>
                            <div className="mx-auto my-auto flex h-full w-full justify-center align-middle sm:w-[50%] md:mx-0 md:justify-end">
                                <a href="#form" className="w-full md:w-[unset]">
                                    <ButtonCTA
                                        onClick={logPress}
                                        className="flex justify-center text-[18px]"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto mt-[30px] max-w-[1170px] lg:mt-[0px]">
                        <div className=" flex max-w-[1170px] flex-col justify-between gap-[10px] md:flex-row lg:gap-[30px]">
                            <Slider
                                dots={false}
                                infinite={true}
                                speed={500}
                                slidesToShow={3}
                                slidesToScroll={1}
                                nextArrow={<SampleNextArrow />}
                                prevArrow={<SamplePrevArrow />}
                                centerMode={false}
                                responsive={[
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 1,
                                        },
                                    },
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                            centerMode: true,
                                            arrows: false,
                                        },
                                    },
                                ]}
                                autoplaySpeed={5000}
                                arrows={true}
                                className="h-full w-full"
                            >
                                {reviews.map((review, index) => (
                                    <Review key={index} review={review} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Reviews
