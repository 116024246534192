import React, { useState } from 'react'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import QuestionNav from '../QuestionNav'

export interface DateProps {
    formData: any
    setFormData: (formData: any) => void
    name: string
    question: string
    subnote: string
    label: string
    required: boolean
    options: any[]
    step: number
    funnelSize: number
    returnToLanding: () => void
    backStep: () => void
    forwardStep: (validate: () => boolean) => void
}

const Date: React.FC<DateProps> = ({
    formData,
    setFormData,
    name,
    question,
    label,
    subnote,
    options,
    required,
    step,
    funnelSize,
    returnToLanding,
    backStep,
    forwardStep,
}) => {
    const [selectError, setDateError] = useState('')
    const [selected, setSelected] = useState<moment.Moment | null>(null)
    const validate = () => {
        if (!selected) {
            setDateError('Please select an option')
            return false
        }
        setDateError('')
        return true
    }

    const updateFormData = (newValue: moment.Moment) => {
        setSelected(newValue)
        let found = false
        const date = newValue.format('MM/DD/YYYY')
        const updatedFunnelResponses = formData.funnelResponses.map(
            (item: any) => {
                if (item.type === name) {
                    console.log('in found')
                    found = true
                    return {
                        ...item,
                        value: date,
                        valueLabel: date,
                    }
                }
                return item
            }
        )

        if (!found) {
            updatedFunnelResponses.push({
                type: name,
                label,
                value: date,
                valueLabel: date,
            })
        }

        setFormData({
            ...formData,
            funnelResponses: updatedFunnelResponses,
        })
    }

    return (
        <>
            <h2
                className="font-Leitura max-w-[670px] py-[30px] text-[24px] text-Black100 md:text-[36px]"
                onClick={validate}
            >
                {question}
            </h2>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label="Select a date"
                    value={selected}
                    onChange={(newValue) => updateFormData(newValue!)}
                    maxDate={moment()}
                    slotProps={{ textField: { fullWidth: true } }}
                />
            </LocalizationProvider>
            <span
                className={
                    (selectError !== '' ? 'block' : 'hidden') +
                    ' w-full text-center text-Red600'
                }
            >
                {selectError}
            </span>
            <QuestionNav
                step={step}
                funnelSize={funnelSize}
                returnToLanding={returnToLanding}
                backStep={backStep}
                forwardStep={() => {
                    forwardStep(validate)
                }}
            />
        </>
    )
}

export default Date
