import slateSerializer from '@firmleads/slate-serializer'
import React from 'react'

interface LegalInfoProps {
    heading?: string
    section1_heading?: string
    section1_content?: any
    section2_heading?: string
    section2_content?: any
    section3_heading?: string
    section3_content?: any
    section4_heading?: string
    section4_content?: any
    section5_heading?: string
    section5_content?: any
    section6_heading?: string
    section6_content?: any
    section7_heading?: string
    section7_content?: any
    section8_heading?: string
    section8_content?: any
    section9_heading?: string
    section9_content?: any
    section10_heading?: string
    section10_content?: any
    section11_heading?: string
    section11_content?: any
    section12_heading?: string
    section12_content?: any
    section13_heading?: string
    section13_content?: any
    section14_heading?: string
    section14_content?: any
    section15_heading?: string
    section15_content?: any
    section16_heading?: string
    section16_content?: any
    section17_heading?: string
    section17_content?: any
    section18_heading?: string
    section18_content?: any
    section19_heading?: string
    section19_content?: any
    section20_heading?: string
    section20_content?: any
    section21_heading?: string
    section21_content?: any
    section22_heading?: string
    section22_content?: any
    section23_heading?: string
    section23_content?: any
    section24_heading?: string
    section24_content?: any
}

const LegalInfo: React.FC<LegalInfoProps> = ({
    heading,
    section1_heading,
    section1_content,
    section2_heading,
    section2_content,
    section3_heading,
    section3_content,
    section4_heading,
    section4_content,
    section5_heading,
    section5_content,
    section6_heading,
    section6_content,
    section7_heading,
    section7_content,
    section8_heading,
    section8_content,
    section9_heading,
    section9_content,
    section10_heading,
    section10_content,
    section11_heading,
    section11_content,
    section12_heading,
    section12_content,
    section13_heading,
    section13_content,
    section14_heading,
    section14_content,
    section15_heading,
    section15_content,
    section16_heading,
    section16_content,
    section17_heading,
    section17_content,
    section18_heading,
    section18_content,
    section19_heading,
    section19_content,
    section20_heading,
    section20_content,
    section21_heading,
    section21_content,
    section22_heading,
    section22_content,
    section23_heading,
    section23_content,
    section24_heading,
    section24_content,
}) => {
    return (
        <div className="font-Arial pb-[50px]">
            {heading !== '' && (
                <div className="font-Leitura flex h-[160px] bg-BgColor md:h-[110px] lg:h-[200px]">
                    <h1 className="ml-[20px] flex w-[328px] flex-row items-center  text-[36px] lg:container md:w-[auto] lg:pl-40 lg:text-[48px] xl:pl-60 2xl:pl-80">
                        {heading}
                    </h1>
                </div>
            )}
            <div className="mx-auto max-w-[1280px]">
                {section1_heading && section1_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section1_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section1_content)}
                        </p>
                    </div>
                )}
                {section2_heading && section2_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section2_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section2_content)}
                        </p>
                    </div>
                )}
                {section3_heading && section3_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section3_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section3_content)}
                        </p>
                    </div>
                )}
                {section4_heading && section4_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section4_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section4_content)}
                        </p>
                    </div>
                )}
                {section5_heading && section5_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section5_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section5_content)}
                        </p>
                    </div>
                )}
                {section6_heading && section6_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section6_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section6_content)}
                        </p>
                    </div>
                )}
                {section7_heading && section7_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section7_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section7_content)}
                        </p>
                    </div>
                )}
                {section8_heading && section8_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section8_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section8_content)}
                        </p>
                    </div>
                )}
                {section9_heading && section9_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section9_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section9_content)}
                        </p>
                    </div>
                )}
                {section10_heading && section10_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section10_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section10_content)}
                        </p>
                    </div>
                )}
                {section11_heading && section11_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section11_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section11_content)}
                        </p>
                    </div>
                )}
                {section12_heading && section12_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section12_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section12_content)}
                        </p>
                    </div>
                )}
                {section13_heading && section13_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section13_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section13_content)}
                        </p>
                    </div>
                )}
                {section14_heading && section14_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section14_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section14_content)}
                        </p>
                    </div>
                )}
                {section15_heading && section15_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section15_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section15_content)}
                        </p>
                    </div>
                )}
                {section16_heading && section16_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section16_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section16_content)}
                        </p>
                    </div>
                )}
                {section17_heading && section17_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section17_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section17_content)}
                        </p>
                    </div>
                )}
                {section18_heading && section18_heading !== '' && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section18_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section18_content)}
                        </p>
                    </div>
                )}
                {section19_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section19_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section19_content)}
                        </p>
                    </div>
                )}
                {section20_heading && section20_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section20_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section20_content)}
                        </p>
                    </div>
                )}
                {section21_heading && section21_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section21_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section21_content)}
                        </p>
                    </div>
                )}
                {section22_heading && section22_heading && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section22_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section22_content)}
                        </p>
                    </div>
                )}
                {section23_heading && section23_heading !== '' && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section23_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section23_content)}
                        </p>
                    </div>
                )}
                {section24_heading && section24_heading !== '' && (
                    <div className="">
                        <h2 className="font-Leitura pt-[50px] text-[24px]">
                            {section24_heading}
                        </h2>
                        <p className="pt-[20px]">
                            {slateSerializer(section24_content)}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LegalInfo
