import { useEffect, useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { db } from '../../config/firebase'
import { doc, getDoc } from 'firebase/firestore'
import RequestDetail from './RequestDetail'
import StateLogo from '../../components/StateLogo'
// import Logo from '../../assets/images/LogoWhite.svg'

const Request = () => {
    const { requestId } = useParams()
    const location = useLocation()
    const [request, setRequest] = useState<any>({})
    const [pending, setPending] = useState(true)

    const navigate = useNavigate()
    const { state } = location

    useEffect(() => {
        const get_request = async () => {
            setPending(true)
            try {
                const requestData = await db.findByID({
                    collection: 'requests',
                    id: request,
                })
                setRequest(requestData)
            } catch {}
        }

        get_request()
    }, [requestId])

    if (pending) {
        return (
            <div className="flex min-h-screen items-center justify-center lg:pb-[200px]">
                <div>
                    <StateLogo
                        white={true}
                        className="mx-auto h-[75px] lg:mb-[100px] lg:h-[110px]"
                    />

                    <div className="lg:flex">
                        <div className="loader mx-auto mb-[30px] mt-[50px] lg:mx-0 lg:mb-0 lg:mr-[30px] lg:mt-0"></div>
                        <div className="text-center lg:text-left">
                            <p>
                                Just a moment while we find your request for an
                                attorney.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    if (!requestId || (state && state.no_lawyer)) {
        navigate(`/searching${location.search}`, {
            state,
        })
    }
    if (Object.keys(request).length === 0 && state !== null) {
        return <RequestDetail data={state} />
    }
    return <RequestDetail data={request} />
}

export default Request
