import Handlebars from 'handlebars'
import { getDomainNameReadable, getStateNameFromUrl } from '@firmleads/utils'

let variables = {
    PAGE_NAME_READABLE: getDomainNameReadable(window.location.hostname),
    STATE_NAME: getStateNameFromUrl(window.location.hostname),
}
console.log('variables', variables)
export const setHandlebarsVariables = (newVariables: any) => {
    variables = { ...variables, ...newVariables }
}

export const handlebarsOut = (templateString: string) => {
    const compiledTemplate = Handlebars.compile(templateString)
    return compiledTemplate(variables)
}
