import React from 'react'
import ProgressCheck from '../../../assets/images/ProgressCheck.svg'

interface ProgressProps {
    step: number
    funnelSize: number
}

const Progress: React.FC<ProgressProps> = ({ step, funnelSize }) => {
    const renderSteps = () => {
        const steps = []
        for (let i = 1; i <= funnelSize + 1; i++) {
            steps.push(
                <React.Fragment key={i}>
                    <div className="flex items-center">
                        <p
                            className={`font-Arial mx-[10px] h-[24px] w-[24px] rounded-full  text-center text-[14px] ${
                                step + 1 < i
                                    ? 'bg-Black500 pt-[2px] text-white'
                                    : step + 1 === i
                                      ? 'border-[2px] border-CurStep bg-white text-CurStep'
                                      : ''
                            }`}
                        >
                            {step + 1 > i ? (
                                <img src={ProgressCheck} alt="check" />
                            ) : (
                                i
                            )}
                        </p>
                    </div>
                    {i <= funnelSize && (
                        <div className="flex-grow">
                            <div
                                className={`mx-[10px] h-[2px] ${step + 1 > i ? 'bg-CurStep' : 'bg-Black500'}`}
                            ></div>
                        </div>
                    )}
                </React.Fragment>
            )
        }
        return steps
    }

    return (
        <div className="flex items-center justify-between  lg:pr-[30px]">
            {renderSteps()}
        </div>
    )
}

export default Progress
