import React from 'react'
import GreenCheckbox from '../../../assets/images/Checkbox.png'
import LawyerReview from '../LawyerReview'
import MailIcon from '../../../assets/images/MailIcon.svg'
import WebIcon from '../../../assets/images/WebIcon.svg'
import PhoneIcon from '../../../assets/images/PhoneIcon.svg'
import { addArticle } from '../../../components/AddArticle'
import { convertToFullName } from '@firmleads/utils'

interface RequestDetailProps {
    data: any
}

const RequestDetail: React.FC<RequestDetailProps> = ({ data }) => {
    const { lawyer, lead } = data

    // useEffect(() => {
    //     window.dataLayer = window.dataLayer || []
    //     window.dataLayer.push({
    //         event: 'conversion',
    //         conversion_value: conversion_value ?? 0,
    //         lead_id: lead_id ?? 'no_lead',
    //     })
    // }, [])

    function practiceAreasToString() {
        let result = ''
        for (let i = 0; i < lawyer.practiceAreas.length - 1; i++) {
            const item = lawyer.practiceAreas[i]
            result +=
                item.substring(0, 1).toUpperCase() +
                item.substring(1, item.length) +
                ', '
        }
        const last_item = lawyer.practiceAreas[lawyer.practiceAreas.length - 1]
        return (
            result +
            last_item.substring(0, 1).toUpperCase() +
            last_item.substring(1, last_item.length)
        )
    }

    if (!lawyer || !lead) return <div>Loading...</div>

    return (
        <div>
            <div className="flex justify-center bg-BgColor py-[50px] pl-5 lg:py-[40px] lg:pl-0">
                <img
                    src={GreenCheckbox}
                    className="mr-[10px] h-[24px] w-[24px]"
                    alt="green img"
                />
                <p className="max-w-[500px] pr-6">
                    Great news{' '}
                    <strong>
                        {lead.first_name !== ''
                            ? lead.first_name.trim()
                            : 'Friend'}
                    </strong>
                    , we have successfully connected you with{' '}
                    {lead.area ? addArticle(lead.area) : 'an'} attorney that
                    serves {convertToFullName(lead.state)}. For{' '}
                    {/* serves {lead.city}, {lead.rstate}. For */}
                    immediate assistance with your case, contact the provider
                    listed below.
                </p>
            </div>
            <div className="block justify-center p-5 py-[20px] lg:flex lg:py-[40px]">
                <div className="mx-auto max-h-[670px] max-w-[370px] bg-BgColor p-[30px] lg:mx-0 ">
                    <div className="flex items-center justify-center">
                        <div className="flex h-[260px] w-[260px] items-center justify-center  bg-Neutral000 lg:h-[310px] lg:w-[310px]">
                            <img
                                src={lawyer.picture ?? lawyer.logo}
                                alt="Lawyer"
                            />
                        </div>
                    </div>
                    <div>
                        <h4 className="mt-[30px] text-[16px] font-bold text-MainColor lg:text-[18px]">{`${lawyer.first_name}  ${lawyer.last_name}`}</h4>
                        <p>{lawyer.firmAddress.display}</p>
                    </div>
                    <div className="mt-[20px]">
                        {lawyer.phone &&
                            lawyer.phone !== '' &&
                            lawyer.phone !== '+' && (
                                <div className="mb-[5px] flex">
                                    <img
                                        className="mr-[10px] h-[24px] w-[24px]"
                                        src={PhoneIcon}
                                        alt="phone icon"
                                    />
                                    <p>{lawyer.phone}</p>
                                </div>
                            )}
                        {lawyer.email && lawyer.email !== '' && (
                            <div className="mb-[5px] flex">
                                <img
                                    className="mr-[10px] h-[24px] w-[24px]"
                                    src={MailIcon}
                                    alt="mail icon"
                                />
                                <p>{lawyer.email}</p>
                            </div>
                        )}
                        {lawyer.website && lawyer.website !== '' && (
                            <div className="flex">
                                <img
                                    className="mr-[10px] h-[24px] w-[24px]"
                                    src={WebIcon}
                                    alt="web icon"
                                />
                                <a href={lawyer.website}>{lawyer.website}</a>
                            </div>
                        )}
                    </div>
                    <div className="mt-[20px]">
                        <h4 className="text-[16px] font-bold text-MainColor lg:text-[18px]">
                            Practice Areas
                        </h4>
                        <p>{practiceAreasToString()}</p>
                    </div>
                </div>
                <div className="max-w-[770px] p-5 lg:ml-[30px]">
                    <h2 className="font-Leitura mt-[30px] pb-[20px] text-[24px] text-Black100 md:text-[36px]">
                        {lawyer.firm_name}
                    </h2>
                    <p className="font-Arial border-b-[1px] border-Neutral300 pb-[30px] text-justify text-[16px] text-Black400 md:text-[18px]">
                        {lawyer.bio}
                    </p>
                    <div className="border-b-[1px] border-Neutral300 pb-[30px]">
                        {lawyer.reviews.map((review: any) => (
                            <LawyerReview review={review} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestDetail
