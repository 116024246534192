import { Timestamp } from 'firebase/firestore'
import React from 'react'

interface FloatingChatMessageProps {
    item: any
    index: number
}
const FloatingChatMessage: React.FC<FloatingChatMessageProps> = ({
    item,
    index,
}) => {
    function formatTimestamp(timestamp: Timestamp): string {
        const currentTime = new Date()
        const messageTime = timestamp.toDate()

        // Convert message time to client's local time zone
        const localMessageTime = new Date(
            messageTime.toLocaleString('en-US', {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
        )

        const elapsedMinutes = Math.floor(
            (+currentTime - +localMessageTime) / (1000 * 60)
        )
        const elapsedHours = Math.floor(elapsedMinutes / 60)

        if (elapsedMinutes < 60) {
            return `${elapsedMinutes} minute${elapsedMinutes !== 1 ? 's' : ''} ago`
        } else if (elapsedHours < 8) {
            return `${elapsedHours} hour${elapsedHours !== 1 ? 's' : ''} ago`
        } else {
            const localTime = localMessageTime.toLocaleTimeString([], {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
            return localTime
        }
    }
    const renderMessage = (message: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return message.split(urlRegex).map((part, i) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={i}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="overflow-wrap break-all text-blue-500 underline"
                    >
                        {part}
                    </a>
                )
            }
            return part
        })
    }
    return (
        <>
            {item.receiverId === 'user' && item.type === 'message' && (
                <>
                    <div className="group ml-auto flex items-center justify-end gap-2">
                        <div
                            className="w-fit max-w-[80%] rounded-3xl bg-[#007AFF] px-5 py-[10px] text-start text-white"
                            key={index}
                        >
                            {item.message}
                        </div>
                    </div>
                    {item.created && (
                        <div className="mb-[10px] mt-[2px] flex items-center justify-end gap-2 text-xs text-Neutral500">
                            {formatTimestamp(item.created)}
                        </div>
                    )}
                </>
            )}
            {item.receiverId === 'system' && item.type === 'message' && (
                <>
                    {item.created && (
                        <div className="mb-[30px] mt-[20px] flex items-center justify-center gap-2 text-xs font-bold text-Neutral500">
                            {item.message}
                        </div>
                    )}
                </>
            )}
            {item.receiverId === 'assistant' && item.type === 'message' && (
                <>
                    <div
                        className="me-auto w-fit max-w-[80%] rounded-3xl bg-Neutral300 px-5 py-[10px] text-start"
                        key={index}
                    >
                        {renderMessage(item.message)}
                    </div>

                    {item.created && (
                        <div className="mb-[10px] mt-[2px] flex items-center gap-2 text-xs text-Neutral500">
                            <img
                                src="https://admin.firmleads.io/media/IzanHeadshot.png"
                                alt="head shot"
                                className="h-[24px] w-[24px] rounded-full object-cover"
                            />
                            {formatTimestamp(item.created)}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default FloatingChatMessage
