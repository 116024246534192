import React from 'react'
import BackArrow from '../../../assets/images/BackArrow.png'

interface QuestionNavProps {
    step: number
    funnelSize: number
    returnToLanding: () => void
    backStep: () => void
    forwardStep: () => void
    directToFunnel?: boolean
}

const QuestionNav: React.FC<QuestionNavProps> = ({
    step,
    funnelSize,
    directToFunnel,
    returnToLanding,
    backStep,
    forwardStep,
}) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This gives a smooth scrolling effect
        })
    }
    return (
        <div
            className={
                (step < funnelSize ? 'block' : 'hidden') +
                ' mt-[30px] flex justify-between'
            }
        >
            {!directToFunnel && (
                <button
                    type="button"
                    onClick={
                        step === 0
                            ? () => {
                                  returnToLanding()
                                  scrollToTop()
                              }
                            : () => {
                                  backStep()
                                  scrollToTop()
                              }
                    }
                    className="font-Arial flex items-center justify-center rounded-[8px] border-[2px]  border-Neutral300 px-[16px] py-[16px] text-[18px] font-bold text-Black400 lg:px-[30px]"
                >
                    <img
                        className="lg:mr-[10px]"
                        src={BackArrow}
                        alt="back arrow"
                    />
                    <span className="hidden lg:block">Back</span>
                </button>
            )}

            {directToFunnel && <div></div>}

            <button
                type="button"
                onClick={() => {
                    forwardStep()
                    scrollToTop()
                }}
                className="font-Arial rounded-[8px] bg-SecondColor px-[30px] py-[16px] text-[16px] font-bold text-Neutral000 lg:text-[18px]"
            >
                Continue
            </button>
        </div>
    )
}

export default QuestionNav
