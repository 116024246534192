import React from 'react'
import { handlebarsOut } from '../../../utils'
import Handshake from '../../../assets/images/Handshake.png'
//import { getStateNameFromUrl } from '../@firmleads/utils'

interface NoHugeFeesProps {
    content: any
}

const NoHugeFees: React.FC<NoHugeFeesProps> = ({ content }) => (
    <div className="bg-BgColor px-4 py-[50px] md:px-5 md:py-[100px]">
        <div className="mx-auto max-w-[1170px] lg:flex">
            <div className="mx-auto pb-[30px] md:w-[570px] lg:my-auto lg:mr-[60px] xl:mr-[100px]">
                <h2
                    className="font-Leitura pb-[20px] text-[24px] text-Black100 md:text-[36px]"
                    dangerouslySetInnerHTML={{
                        __html: handlebarsOut(content.headline),
                    }}
                >
                    {/*Legal Help Without Huge Up-Front Fees!*/}
                </h2>

                <p
                    className="font-Arial text-justify text-[16px] text-Black400 md:text-[18px]"
                    dangerouslySetInnerHTML={{
                        __html: handlebarsOut(content.description1),
                    }}
                >
                    {/*With {getStateNameFromUrl(window.location.hostname)} Legal
                    Help you can hire a lawyer that offers unbundled legal
                    services. Meaning the lawyer assists you with the parts of
                    your case you need help with, and then you can handle parts
                    on your own to save money.*/}
                </p>
                <p
                    className=" font-Arial pt-[10px] text-justify text-[16px] text-Black400 md:text-[18px]"
                    dangerouslySetInnerHTML={{
                        __html: handlebarsOut(content.description2),
                    }}
                >
                    {/*In other words, if your case is a good fit to be unbundled,
                    you will NOT be required to pay thousands of dollars up
                    front.*/}
                </p>
            </div>
            <img
                className="mx-auto rounded-lg"
                src={Handshake}
                alt="handshake"
                loading={'lazy'}
            />
        </div>
    </div>
)

export default NoHugeFees
