import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'

export function parsePhoneToReadable(phone: string) {
    const phoneUtil = PhoneNumberUtil.getInstance()

    try {
        const number = phoneUtil.parse(phone)

        const formattedNumber = phoneUtil.format(
            number,
            PhoneNumberFormat.NATIONAL
        )

        const countryCode = number.getCountryCode()

        if (countryCode === 1) {
            return formattedNumber
        }

        return '+' + countryCode + ' ' + formattedNumber
    } catch (error) {
        return phone
    }
}


export const leadTypeMapReadable: any = {
    immigration: 'Immigration',
    business: 'Business',
    wills: 'Estate',
    trusts: 'Estate',
    poa: 'Estate',
    estate: 'Estate',
    probate: 'Probate',
    guardianship: 'Guardianship',
    divorce: 'Divorce',
    custody: 'Family',
    family: 'Family',
    adoption: 'Family',
    dui: 'DUI',
    criminal: 'Criminal',
    bankruptcy: 'Bankruptcy',
    realestate: 'Real Estate',
    landlord: 'Landlord',
    tenant: 'Tenant',
    landuse: 'Real Estate',
    traffic: 'Traffic',
    injury: 'Personal Injury',
    auto: 'Auto Accident',
    employment: 'Employment',
}

export const statesShortToLong: { [key: string]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
}

export const statesLongToShort: { [key: string]: string } = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaiʻi: 'HI',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
}


export function formatCurrency(amount: number, includeCents: boolean = true) {
    if (typeof amount !== 'number') {
        throw new TypeError('Input must be a number')
    }

    const absAmount = Math.abs(amount)
    const formatted = absAmount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    if (includeCents) {
        return formatted
    } else {
        return formatted.replace(/\.00$/, '')
    }
}

export function convertToE164(phoneNumber: string) {
    const cleanedNumber = phoneNumber.replace(/\D/g, '')

    if (phoneNumber[0] === '+') {
        return '+' + cleanedNumber
    }

    if (cleanedNumber.length === 10) {
        return `+1${cleanedNumber}`
    }

    return `+${cleanedNumber}`
}

export function formatTimestamp(timestamp: Timestamp | null): string {
    if (timestamp && typeof timestamp.toDate === 'function') {
        return moment(timestamp.toDate()).format('MM/DD/YYYY hh:mm a')
    }
    return 'Updating...' // or any other placeholder text
}

export const parsePracticeAreas = (curAreas: {subdomain:string}[], areasMap: Record<string, string>) => {
    let practiceAreasReadable = ''
    for (let i = 0; i < curAreas.length; i++) {
        const area = curAreas[i]!
        if (i === curAreas.length - 1) {
            
            practiceAreasReadable += areasMap[area.subdomain]
        } else {
            practiceAreasReadable += areasMap[area.subdomain] + ', '
        }
    }
    return practiceAreasReadable
}

export const parseStringArray = (arr: string[]) => {
    let arrReadable = ''

    for (let i = 0; i < arr.length; i++) {
        if (i === arr.length - 1) {
            arrReadable += arr[i]
        } else {
            arrReadable += arr[i] + ', '
        }
    }

    return arrReadable
}

export function parseJurisdiction(jurisdiction: 
    { entireState: boolean; counties:{ county: string}[]; state:string }[]
) {
    const states = jurisdiction.map((item)=>item.state)

    return parseStringArray(states)
}


const states: { [key: string]: string } = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
}

const devHostMap: { [key: string]: string } = {
    hawaii: 'legalhelphawaii',
    or: 'legalhelporegon',
    sc: 'legalhelpsouthcarolina',
    ca: 'californialawconnect',
    tn: 'legalhelptennessee',
    oh: 'legalhelpohio',
    fl: 'floridalegalconnect',
    ny: 'newyorklawconnect',
    ga: 'georgialawconnect',
    tx: 'texaslawconnect',
    nc: 'northcarolinalawconnect',
    firmleads: 'firmleads',
}

const hostReadableMap: { [key: string]: string } = {
    hawaii: 'Hawaii Legal Help',
    legalhelphawaii: 'Hawaii Legal Help',
    or: 'Oregon Legal Help',
    legalhelporegon: 'Oregon Legal Help',
    sc: 'South Carolina Legal Help',
    legalhelpsouthcarolina: 'South Carolina Legal Help',
    californialawconnect: 'California Law Connect',
    ca: 'California Law Connect',
    firmleads: 'FirmLeads',
    tn: 'Tennessee Legal Help',
    legalhelptennessee: 'Tennessee Legal Help',
    oh: 'Ohio Legal Help',
    legalhelpohio: 'Ohio Legal Help',
    fl: 'Florida Legal Connect',
    floridalegalconnect: 'Florida Legal Connect',
    ny: 'New York Law Connect',
    newyorklawconnect: 'New York Law Connect',
    tx: 'Texas Law Connect',
    texaslawconnect: 'Texas Law Connect',
    nc: 'North Carolina Law Connect',
    northcarolinalawconnect: 'North Carolina Law Connect',
    ga: 'Georgia Law Connect',
    georgialawconnect: 'Georgia Law Connect',
}

const stateReadableMap: { [key: string]: string } = {
    hawaii: 'Hawaii',
    legalhelphawaii: 'Hawaii',
    or: 'Oregon',
    legalhelporegon: 'Oregon',
    sc: 'South Carolina',
    legalhelpsouthcarolina: 'South Carolina',
    californialawconnect: 'California',
    ca: 'California',
    firmleads: 'Hawaii',
    tn: 'Tennessee',
    legalhelptennessee: 'Tennessee',
    oh: 'Ohio',
    legalhelpohio: 'Ohio',
    fl: 'Florida',
    floridalegalconnect: 'Florida',
    ny: 'New York',
    newyorklawconnect: 'New York',
    tx: 'Texas',
    texaslawconnect: 'Texas',
    nc: 'North Carolina',
    northcarolinalawconnect: 'North Carolina',
    ga: 'Georgia',
    georgialawconnect: 'Georgia',
}

export function getDomainName(url: string) {
    try {
        const urlObj = new URL(
            url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
        )
        const parts = urlObj.hostname.split('.')

        if (urlObj.hostname.includes('dev-url')) {
            
            return parts.length > 2
                ? devHostMap[parts[parts.length - 3]!]
                : devHostMap[parts[0]!]
        }

        if (urlObj.hostname.includes('localhost')) return 'californialawconnect' //'legalhelpsouthcarolina'

        return parts.length > 1 ? parts[parts.length - 2] : parts[0]
    } catch (error) {
        console.error('Invalid URL:', error)
        return null
    }
}

export function getDomainNameReadable(url: string) {
    try {
        const urlObj = new URL(
            url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
        )
        const parts = urlObj.hostname.split('.')

        if (urlObj.hostname.includes('dev-url')) {
            return parts.length > 2
                ? hostReadableMap[parts[parts.length - 3]!]
                : hostReadableMap[parts[0]!]
        }

        if (urlObj.hostname.includes('localhost')) return 'Hawaii Legal Help'

        return parts.length > 1
            ? hostReadableMap[parts[parts.length - 2]!]
            : hostReadableMap[parts[0]!]
    } catch (error) {
        console.error('Invalid URL:', error)
        return null
    }
}

export function getStateNameFromUrl(url: string) {
    try {
        const urlObj = new URL(
            url.startsWith('http://') || url.startsWith('https://')
                ? url
                : `https://${url}`
        )
        const parts = urlObj.hostname.split('.')

        if (urlObj.hostname.includes('dev-url')) {
            return parts.length > 2
                ? stateReadableMap[parts[parts.length - 3]!]
                : stateReadableMap[parts[0]!]
        }

        if (urlObj.hostname.includes('localhost')) return 'Hawaii'

        return parts.length > 1
            ? stateReadableMap[parts[parts.length - 2]!]
            : stateReadableMap[parts[0]!]
    } catch (error) {
        console.error('Invalid URL:', error)
        return null
    }
}

export const leadTypeMap: any = {
    auto: 'auto',
    immigration: 'immigration',
    wills: 'estate',
    trusts: 'estate',
    poa: 'estate',
    probate: 'probate',
    guardianship: 'guardianship',
    estate: 'estate',
    divorce: 'family',
    custody: 'family',
    family: 'family',
    adoption: 'family',
    dui: 'dui',
    criminal: 'criminal',
    bankruptcy: 'bankruptcy',
    realestate: 'realestate',
    landlord: 'landlord',
    tenant: 'tenant',
    landuse: 'realestate',
    traffic: 'traffic',
    injury: 'injury',
}

export const possibleLandingPages: string[] = [
    'immigration',
    'wills',
    'trusts',
    'poa',
    'probate',
    'guardianship',
    'estate',
    'divorce',
    'custody',
    'family',
    'adoption',
    'dui',
    'criminal',
    'bankruptcy',
    'business',
    'realestate',
    'landlord',
    'tenant',
    'landuse',
    'traffic',
    'injury',
]

export const urlToPhoneNumber: { [key: string]: string } = {
    'californialawconnect.com': '+12138367339',
    'legalhelphawaii.com': '+18085090175',
    'family.localhost': '+18085090175',
    'dev-url.app': '+18085090175',
    'legalhelporegon.com': '+15034472971',
    'legalhelpwashingtonstate.com': '+12065960737',
    'legalhelpsouthcarolina.com': '+18038910038',
    'floridalegalconnect.com': '+19044470332',
    'legalhelpohio.com': '+16146470947',
    'legalhelptennessee.com': '+16154369823',
    'newyorklawconnect.com': '+15184506477',
    'texaslawconnect.com': '+12142394237',
    'northcarolinalawconnect.com': '+19193008318',
    'georgialawconnect.com': '+14043835585',
}

export const urlToGreeting: { [key: string]: string } = {
    'californialawconnect.com': 'Hello from California Law Connect',
    'legalhelphawaii.com': 'Aloha from Hawaii Legal Help',
    'legalhelporegon.com': 'Hello from Oregon Legal Help',
    'legalhelpwashingtonstate.com': 'Hello from Washington Legal Help',
    'legalhelpsouthcarolina.com': 'Hello from South Carolina Legal Help',
    'floridalegalconnect.com': 'Hello from Florida Legal Connect',
    'legalhelpohio.com': 'Hello from Ohio Legal Help',
    'legalhelptennessee.com': 'Hello from Tennessee Legal Help',
    'newyorklawconnect.com': 'Hello from New York Law Connect',
    'texaslawconnect.com': 'Hello from Texas Law Connect',
    'northcarolinalawconnect.com': 'Hello from North Carolina Law Connect',
    'georgialawconnect.com': 'Hello from Georgia Law Connect',
}

export const urlToGoodbye: { [key: string]: string } = {
    'legalhelphawaii.com': 'Mahalo',
    'californialawconnect.com': 'Thank you',
    'legalhelporegon.com': 'Thank you',
    'legalhelpwashingtonstate.com': 'Thank you',
    'legalhelpsouthcarolina.com': 'Thank you',
    'floridalegalconnect.com': 'Thank you',
    'legalhelpohio.com': 'Thank you',
    'legalhelptennessee.com': 'Thank you',
    'newyorklawconnect.com': 'Thank you',
    'texaslawconnect.com': 'Thank you',
    'northcarolinalawconnect.com': 'Thank you',
    'georgialawconnect.com': 'Thank you',
}

export const urlToName: { [key: string]: string } = {
    'dev-url.app': 'Hawaii Legal Help',
    'legalhelphawaii.com': 'Hawaii Legal Help',
    'californialawconnect.com': 'California Law Connect',
    'legalhelporegon.com': 'Oregon Legal Help',
    'legalhelpwashingtonstate.com': 'Washington Legal Help',
    'legalhelpsouthcarolina.com': 'South Carolina Legal Help',
    'floridalegalconnect.com': 'Florida Legal Connect',
    'legalhelpohio.com': 'Ohio Legal Help',
    'legalhelptennessee.com': 'Tennessee Legal Help',
    'newyorklawconnect.com': 'New York Law Connect',
    'texaslawconnect.com': 'Texas Law Connect',
    'northcarolinalawconnect.com': 'North Carolina Law Connect',
    'georgialawconnect.com': 'Georgia Law Connect',
}

export const urlToTheme: { [key: string]: any } = {
    'dev-url.app': {
        mainTheme: '#36998e',
        bgIcon: 'rgba(54, 153, 142, 0.2)',
        footerColor: '#484848',
    },
    'legalhelphawaii.com': {
        mainTheme: '#36998e',
        bgIcon: 'rgba(54, 153, 142, 0.2)',
        footerColor: '#484848',
    },
    'legalhelporegon.com': {
        mainTheme: '#36998e',
        bgIcon: 'rgba(54, 153, 142, 0.2)',
        footerColor: '#484848',
    },
    'legalhelpwashingtonstate.com': {
        mainTheme: '#36998e',
        bgIcon: 'rgba(54, 153, 142, 0.2)',
        footerColor: '#484848',
    },
    'legalhelpsouthcarolina.com': {
        mainTheme: '#90792e',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
    'legalhelptennessee.com': {
        mainTheme: '#90792e',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
    'californialawconnect.com': {
        mainTheme: '695230',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
    'newyorklawconnect.com': {
        mainTheme: '003884',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
    'texaslawconnect.com': {
        mainTheme: '003884',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
    'northcarolinalawconnect.com': {
        mainTheme: '003884',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
    'georgialawconnect.com': {
        mainTheme: '003884',
        bgIcon: 'rgba(144, 121, 46, 0.2)',
        footerColor: '#484848',
    },
}

export const areaCodeToState: { [key: string]: string } = {
    '808': 'hawaii',
    '503': 'oregon',
    '206': 'washingtonState',
    '415': 'california',
    '213': 'california',
    '803': 'southCarolina',
}

export const spanishToEnglishStates: { [key: string]: string } = {
    Alabama: 'Alabama',
    Alaska: 'Alaska',
    Arizona: 'Arizona',
    Arkansas: 'Arkansas',
    California: 'California',
    'Carolina del Norte': 'North Carolina',
    'Carolina del Sur': 'South Carolina',
    Colorado: 'Colorado',
    Connecticut: 'Connecticut',
    'Dakota del Norte': 'North Dakota',
    'Dakota del Sur': 'South Dakota',
    Delaware: 'Delaware',
    Florida: 'Florida',
    Georgia: 'Georgia',
    Hawái: 'Hawaii',
    Idaho: 'Idaho',
    Illinois: 'Illinois',
    Indiana: 'Indiana',
    Iowa: 'Iowa',
    Kansas: 'Kansas',
    Kentucky: 'Kentucky',
    Luisiana: 'Louisiana',
    Maine: 'Maine',
    Maryland: 'Maryland',
    Massachusetts: 'Massachusetts',
    Míchigan: 'Michigan',
    Minnesota: 'Minnesota',
    Misisipi: 'Mississippi',
    Misuri: 'Missouri',
    Montana: 'Montana',
    Nebraska: 'Nebraska',
    Nevada: 'Nevada',
    'Nueva Jersey': 'New Jersey',
    'Nueva York': 'New York',
    'Nuevo Hampshire': 'New Hampshire',
    'Nuevo México': 'New Mexico',
    Ohio: 'Ohio',
    Oklahoma: 'Oklahoma',
    Oregón: 'Oregon',
    Pensilvania: 'Pennsylvania',
    'Rhode Island': 'Rhode Island',
    Tennessee: 'Tennessee',
    Texas: 'Texas',
    Utah: 'Utah',
    Vermont: 'Vermont',
    Virginia: 'Virginia',
    'Virginia Occidental': 'West Virginia',
    Washington: 'Washington',
    Wisconsin: 'Wisconsin',
    Wyoming: 'Wyoming',
}

export function convertStateToEnglish(state: string) {
    return spanishToEnglishStates[state] || state
}

export function getStateFromPhone(phoneNumber: string): string | null {
    const areaCodeRegex = /\+1(\d{3})/

    const match = phoneNumber.match(areaCodeRegex)

    return match ? areaCodeToState[match[1]!]! : 'hawaii'
}

export function getPhoneFromUrl(url: string) {
    const result = urlToPhoneNumber[url]

    if (!result) return '+18085090175'

    return result
}

export function getGreetingFromUrl(url: string) {
    const result = urlToGreeting[url]

    if (!result) return 'Aloha from Hawaii Legal Help'

    return result
}

export function getGoodbyeFromUrl(url: string) {
    const result = urlToGoodbye[url]

    if (!result) return 'Thank you'

    return result
}

const statesFullNames: string[] = Object.values(statesShortToLong)

export function convertToFullName(abbreviation: string) {
    let input = abbreviation.toUpperCase()

    if (input.length === 2 && statesShortToLong[input]) {
        return statesShortToLong[input]
    }

    input = convertStateToEnglish(abbreviation)

    input = toTitleCase(input)

    if (statesFullNames.includes(input)) {
        return input
    }

    return 'Unknown State'
}
export function convertToShortName(str: string): string {
    const input = str.trim()

    if (statesLongToShort[input]) {
        return statesLongToShort[input]
    }

    if (
        input.length === 2 &&
        Object.values(statesLongToShort).includes(input.toUpperCase())
    ) {
        return input.toUpperCase()
    }

    return 'Unknown State'
}

export function toTitleCase(str: string) {
    const input = str.toLowerCase()
    return input
        .toLowerCase()
        .split(' ')
        .map((word: string) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        .join(' ')
}

export async function fetchData(url: string): Promise<any> {
    try {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        return data
    } catch (error) {
        console.log('Error:', error)
        throw error
    }
}

export function isFirmleadsDomain(email: string | undefined | null): boolean {
    if (!email) return false

    const domainRegex = /@firmleads\.io$/i

    return domainRegex.test(email)
}

export function getPayPeriod(inputDate: string | moment.Moment): {
    start: string
    end: string
} {
    let date = moment(inputDate)

    let startDate: moment.Moment
    let endDate: moment.Moment

    if (date.date() >= 15 && date.date() <= 28) {
        // Pay period: 15th to 28th of the month
        startDate = moment(date).date(15)
        endDate = moment(date).date(28)
    } else {
        // Pay period: 29th of previous month to 14th of current month
        if (date.date() >= 29) {
            // Current month is after the 29th
            startDate = moment(date).date(29)
            endDate = moment(date).add(1, 'month').date(14)
        } else {
            // Current month is before the 15th
            startDate = moment(date).subtract(1, 'months').date(29)
            endDate = moment(date).date(14)
        }

        // Adjust for February in leap years
        if (
            startDate.month() === 1 &&
            startDate.date() === 29 &&
            startDate.isLeapYear()
        ) {
            endDate.month(2).date(14)
        }
        // Adjust for February in non lead years
        if (
            startDate.month() === 1 &&
            !startDate.isValid() &&
            !startDate.isLeapYear()
        ) {
            // February and invalid date (like 29th in a non-leap year)
            startDate = moment(startDate).month(1).date(28)
        }
    }
    startDate = startDate.startOf('day')
    // set end date to last second of the day
    endDate.add(1, 'days').startOf('day').subtract(1, 'second')
    const start = startDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    const end = endDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    return { start, end }
}

export function getLastPayPeriod() {
    const date = moment()
    const { start } = getPayPeriod(date)

    const day = moment(start).subtract(1, 'days')

    const { start: newStart, end: newEnd } = getPayPeriod(day)

    return { start: newStart, end: newEnd }
}