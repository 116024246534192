import React, { useEffect, useState } from 'react'

import Funnel from '../FunnelV3'
import content from '../../assets/webcopy.json'

import { LandingPage } from '../../../payload'
type Content = { [key: string]: any }

const DirectFunnel: React.FC = () => {
    const [page, setPage] = useState<LandingPage>()

    const locationSplit = window.location.hostname.split('.')
    const landingUrl =
        locationSplit[locationSplit.length - 2] +
        '.' +
        locationSplit[locationSplit.length - 1]

    let subdomain = locationSplit[0]

    useEffect(() => {
        const fetchData = async () => {
            if (
                subdomain === '' ||
                subdomain === undefined ||
                subdomain === null
            )
                return

            console.log('subdomain', subdomain)
            try {
                const response = await fetch(
                    `https://admin.firmleads.io/api/landing-pages?where[subdomain][equals]=${subdomain}`
                )
                const data = await response.json()
                setPage(data.docs[0])
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }

        fetchData()
    }, [subdomain])

    let pageContent = (content as Content)[subdomain]
    if (pageContent === undefined) {
        subdomain = 'family'
        // pageContent = (content as Content)['family']
    }

    console.log(page?.variants![0].funnel!)
    if (!page?.variants![0].funnel!) {
        return (
            <div className="flex min-h-screen items-center justify-center lg:pb-[200px]">
                <div className="loader mx-auto mb-[30px] mt-[50px] lg:mx-0 lg:mb-0 lg:mr-[30px] lg:mt-0"></div>
            </div>
        )
    }
    return (
        <Funnel
            updateHide={() => {}}
            landingPage={subdomain}
            landingUrl={landingUrl}
            city={''}
            state={''}
            initialFunnel={page?.variants![0].funnel!}
            directToFunnel={true}
            contactPrompt={page?.variants![0].contactPrompt!}
            scrollText={pageContent.scrollText}
        />
    )
}

export default DirectFunnel
