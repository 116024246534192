import React, { useState } from 'react'
import QuestionNav from '../QuestionNav'

export interface TextAreaProps {
    formData: any
    setFormData: (formData: any) => void
    name: string
    label: string
    question: string
    placeholder: string
    required: boolean
    step: number
    funnelSize: number
    returnToLanding: () => void
    backStep: () => void
    forwardStep: (validate: () => boolean) => void
}

const TextArea: React.FC<TextAreaProps> = ({
    formData,
    setFormData,
    name,
    label,
    question,
    placeholder,
    required,
    step,
    funnelSize,
    returnToLanding,
    backStep,
    forwardStep,
}) => {
    const [descriptionError, setDescriptionError] = useState('')

    function getValue() {
        if (!formData.funnelResponses) return

        for (let i = 0; i < formData.funnelResponses.length; i++) {
            if (formData.funnelResponses[i].type === name) {
                return formData.funnelResponses[i].value
            }
        }
    }
    const validate = () => {
        let value = getValue()

        if (!value) {
            setDescriptionError('The issue description is required.')
            return false
        }

        if (value === '') {
            setDescriptionError('The issue description is required.')
            return false
        }

        if (value.length < 10) {
            setDescriptionError(
                'The issue description must be between 10 and 650 characters.'
            )
            return false
        }
        const split = value.split(' ')

        if (split.length < 2) {
            setDescriptionError(
                'The issue description must contain at least two words.'
            )
            return false
        }
        setDescriptionError('')
        return true
    }

    return (
        <>
            <h2 className="font-Leitura max-w-[670px] py-[30px] text-[24px] text-Black100 md:text-[36px]">
                {question}
            </h2>
            <textarea
                value={getValue() ?? ''}
                onChange={(e) => {
                    let descriptionFound = false
                    const updatedFunnelResponses = formData.funnelResponses.map(
                        (item: any) => {
                            if (item.type === 'description') {
                                descriptionFound = true
                                return { ...item, value: e.target.value }
                            }
                            return item
                        }
                    )

                    if (!descriptionFound) {
                        updatedFunnelResponses.push({
                            type: name,
                            label,
                            value: e.target.value,
                        })
                    }

                    setFormData({
                        ...formData,
                        funnelResponses: updatedFunnelResponses,
                    })
                }}
                // onBlur={validate}
                className="h-[200px] w-full rounded-[12px] border-[1px] border-Neutral300 px-[20px] py-[16px] text-Black100"
                placeholder={placeholder}
            ></textarea>
            <span
                className={
                    (descriptionError !== '' ? 'block' : 'hidden') +
                    ' w-full text-center text-Red600'
                }
            >
                {descriptionError}
            </span>
            <QuestionNav
                step={step}
                funnelSize={funnelSize}
                returnToLanding={returnToLanding}
                backStep={backStep}
                forwardStep={() => {
                    forwardStep(validate)
                }}
            />
        </>
    )
}

export default TextArea
