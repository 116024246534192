import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'
import { getFirestore } from 'firebase/firestore'
import PayloadSDK from '@firmleads/payload-sdk'

const isProduction =
    process.env.REACT_APP_GCP_PROJECT &&
    process.env.REACT_APP_GCP_PROJECT === 'firmleads-production'

// Set different environment variables based on the environment
export const firebaseConfig = isProduction
    ? {
          apiKey: process.env.REACT_APP_APIKEY,
          authDomain: process.env.REACT_APP_AUTHDOMAIN,
          databaseURL: process.env.REACT_APP_DB,
          projectId: process.env.REACT_APP_PID,
          storageBucket: process.env.REACT_APP_SB,
          messagingSenderId: process.env.REACT_APP_SID,
          appId: process.env.REACT_APP_APPID,
          measurementId: process.env.REACT_APP_MID,
      }
    : {
          apiKey: process.env.REACT_APP_APIKEY_DEV,
          authDomain: process.env.REACT_APP_AUTHDOMAIN_DEV,
          databaseURL: process.env.REACT_APP_DB_DEV,
          projectId: process.env.REACT_APP_PID_DEV,
          storageBucket: process.env.REACT_APP_SB_DEV,
          messagingSenderId: process.env.REACT_APP_SID_DEV,
          appId: process.env.REACT_APP_APPID_DEV,
          measurementId: process.env.REACT_APP_MID_DEV,
      }

const app = initializeApp(firebaseConfig)

export const apiUrl = isProduction
    ? process.env.REACT_APP_API!
    : process.env.REACT_APP_API_DEV!
export const apiKey = isProduction
    ? process.env.REACT_APP_API_KEY!
    : process.env.REACT_APP_API_KEY_DEV!

console.log('API SET UP', apiUrl, apiKey)
export const db = new PayloadSDK('users', apiUrl, apiKey)
export const fdb = getFirestore(app)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app)
