import React from 'react'
import { handlebarsOut } from '../../../utils'
import ButtonCTA from '../../../components/ButtonCTA'

const Plan = [
    {
        id: 1,
        picture:
            '<path d="M12.75 0.500183C15.2109 0.500183 17.5078 1.86737 18.7656 4.00018C20.0234 6.18768 20.0234 8.86737 18.7656 11.0002C17.5078 13.1877 15.2109 14.5002 12.75 14.5002C10.2344 14.5002 7.9375 13.1877 6.67969 11.0002C5.42188 8.86737 5.42188 6.18768 6.67969 4.00018C7.9375 1.86737 10.2344 0.500183 12.75 0.500183ZM10.2344 17.1252H15.2109C16.3594 17.1252 17.4531 17.3439 18.4375 17.6721C18.1641 18.6017 18 19.6408 18 20.6252C18 23.9064 19.5859 26.8049 22.0469 28.5002H2.08594C1.21094 28.5002 0.5 27.7892 0.5 26.9142C0.5 21.5002 4.82031 17.1252 10.2344 17.1252ZM19.75 20.6252C19.75 17.8361 21.2266 15.2658 23.6875 13.8439C26.0938 12.4221 29.1016 12.4221 31.5625 13.8439C33.9688 15.2658 35.5 17.8361 35.5 20.6252C35.5 23.4689 33.9688 26.0392 31.5625 27.4611C29.1016 28.883 26.0938 28.883 23.6875 27.4611C21.2266 26.0392 19.75 23.4689 19.75 20.6252ZM27.625 16.2502C27.1328 16.2502 26.75 16.6877 26.75 17.1252V20.6252C26.75 21.1174 27.1328 21.5002 27.625 21.5002H30.25C30.6875 21.5002 31.125 21.1174 31.125 20.6252C31.125 20.1877 30.6875 19.7502 30.25 19.7502H28.5V17.1252C28.5 16.6877 28.0625 16.2502 27.625 16.2502Z" />',
        text: 'Bypass hours of hassle of searching for the ideal lawyer.',
        content: 'Skip countless hours struggling to find the right lawyer',
    },
    {
        id: 2,
        picture:
            '<path d="M0.75 4.00018C0.75 2.08612 2.28125 0.500183 4.25 0.500183H21.75C23.6641 0.500183 25.25 2.08612 25.25 4.00018V5.75018H0.75V4.00018ZM0.75 7.50018H25.25V25.0002C25.25 26.9689 23.6641 28.5002 21.75 28.5002H4.25C2.28125 28.5002 0.75 26.9689 0.75 25.0002V7.50018ZM6 12.7502C6 13.2424 6.38281 13.6252 6.875 13.6252H19.125C19.5625 13.6252 20 13.2424 20 12.7502C20 12.3127 19.5625 11.8752 19.125 11.8752H6.875C6.38281 11.8752 6 12.3127 6 12.7502ZM6 18.0002C6 18.4924 6.38281 18.8752 6.875 18.8752H19.125C19.5625 18.8752 20 18.4924 20 18.0002C20 17.5627 19.5625 17.1252 19.125 17.1252H6.875C6.38281 17.1252 6 17.5627 6 18.0002ZM6 23.2502C6 23.7424 6.38281 24.1252 6.875 24.1252H12.125C12.5625 24.1252 13 23.7424 13 23.2502C13 22.8127 12.5625 22.3752 12.125 22.3752H6.875C6.38281 22.3752 6 22.8127 6 23.2502Z" />',
        text: 'Briefly describe your legal issue with us',
        content: 'briefly describe your legal issue with us',
    },
    {
        id: 3,
        picture:
            '<path d="M5.75 7.50018C5.75 5.03925 7.0625 2.74237 9.25 1.48456C11.3828 0.226746 14.0625 0.226746 16.25 1.48456C18.3828 2.74237 19.75 5.03925 19.75 7.50018C19.75 10.0158 18.3828 12.3127 16.25 13.5705C14.0625 14.8283 11.3828 14.8283 9.25 13.5705C7.0625 12.3127 5.75 10.0158 5.75 7.50018ZM0.5 26.9142C0.5 21.5002 4.82031 17.1252 10.2344 17.1252H15.2109C20.625 17.1252 25 21.5002 25 26.9142C25 27.7892 24.2344 28.5002 23.3594 28.5002H2.08594C1.21094 28.5002 0.5 27.7892 0.5 26.9142ZM34.6797 10.1799L27.6797 17.1799C27.1328 17.7267 26.3125 17.7267 25.8203 17.1799L22.3203 13.6799C21.7734 13.1877 21.7734 12.3674 22.3203 11.8752C22.8125 11.3283 23.6328 11.3283 24.1797 11.8752L26.75 14.4455L32.8203 8.3205C33.3125 7.82831 34.1328 7.82831 34.625 8.3205C35.1719 8.86737 35.1719 9.68768 34.625 10.1799H34.6797Z" />',
        text: 'Get connected with a lawyer in under 60 seconds',
        content:
            'Once your property is live, qualified renters are selected and Homeowner payouts begin.',
    },
]
interface PlanDetailProps {
    updateHide: () => void
    content: any
    lawCategory: string
}

const PlanDetail: React.FC<PlanDetailProps> = ({
    updateHide,
    content,
    lawCategory,
}) => {
    return (
        <div className="bg-BgColor py-[50px] md:py-[100px]">
            <div className="mx-auto max-w-[1280px] px-4 md:px-5">
                <p
                    className="font-Leitura  mx-auto max-w-[440px] pb-[20px] text-center text-[24px] text-Black100 md:text-[36px]"
                    dangerouslySetInnerHTML={{
                        __html: handlebarsOut(content.headline),
                    }}
                >
                    {/*Finding a {' ' + lawCategory + ' '}
                    <br />
                    Lawyer Just Got Easier*/}
                </p>

                <div
                    className=" mx-auto max-w-[970px] text-left text-[16px] text-Black400 md:text-center md:text-[18px]"
                    dangerouslySetInnerHTML={{
                        __html: handlebarsOut(content.description),
                    }}
                >
                    {/*At {getDomainNameReadable(window.location.hostname)} we saw far
                    too many of our {getStateNameFromUrl(window.location.hostname)}{' '}
                    friends struggling to find the right lawyer to protect their
                    assets and secure their futures. Tell us where you need a
                    lawyer!*/}
                    {/* That's why we hand-picked and vetted{' '}
                {getStateNameFromUrl(window.location.hostname)}'s top-rated{' '}
                {lawCategory.toLowerCase()} lawyers to ensure expertise,
                credentials, and the exceptional service you deserve. */}
                </div>

                <div className="mx-auto flex flex-col justify-center gap-[20px] py-[30px] md:flex-row md:py-[50px] lg:gap-[30px]">
                    {Plan?.map((res) => (
                        <div
                            key={res.id}
                            className="flex w-full  rounded-lg bg-Neutral000 px-[16px] py-[20px] md:w-[370px] md:px-[30px] md:py-[40px]"
                        >
                            <div className="max-h-[36px] max-w-[36px]">
                                <svg
                                    className="h-[36px] w-[36px] fill-current text-MainColor"
                                    viewBox="0 0 36 36"
                                    xmlns="http://www.w3.org/2000/svg"
                                    dangerouslySetInnerHTML={{
                                        __html: res.picture,
                                    }}
                                />
                            </div>
                            <div className="ml-[20px] pt-[10px] md:pt-[0px]">
                                <div className="font-Leitura text-[24px] text-Black100 ">
                                    Step {res.id}
                                </div>
                                <div
                                    className="font-Arial pt-[20px] text-[16px] text-Black400 md:text-[18px]"
                                    dangerouslySetInnerHTML={{
                                        __html: handlebarsOut(
                                            content['step' + res.id]
                                        ),
                                    }}
                                ></div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="font-Arial text-center md:pt-[40px]">
                    <a href="#form">
                        <ButtonCTA className="flex justify-center" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default PlanDetail
