import React, { useState } from 'react'
import QuestionNav from '../QuestionNav'
import CityAutoComplete from '../../../components/CityAutoComplete'

export interface FunnelCitySelectProps {
    formData: any
    setFormData: (formData: any) => void
    name?: string
    label?: string
    subnote?: string
    required?: boolean
    step: number
    funnelSize: number
    directToFunnel?: boolean
    returnToLanding: () => void
    backStep: () => void
    forwardStep: (validate: () => boolean) => void
}

const FunnelCitySelect: React.FC<FunnelCitySelectProps> = ({
    formData,
    setFormData,
    name,
    label,
    subnote,
    required,
    step,
    funnelSize,
    directToFunnel,
    returnToLanding,
    backStep,
    forwardStep,
}) => {
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [locationError, setLocationError] = useState(false)

    const validate = () => {
        if (city === '' && state === '') {
            setLocationError(true)
            return false
        }

        setLocationError(false)
        setFormData({ ...formData, city, state })
        return true
    }

    // const handleSubmit = (e: any) => {
    //     if (city !== '' && state !== '') {
    //         logEvent(analytics, 'locationEntered')
    //         updateCity(city)
    //         updateState(state)
    //         updateHide()
    //     } else {
    //         setLocationError(true)
    //     }
    // }

    return (
        <>
            <h2
                className="font-Leitura max-w-[670px] py-[30px] text-[24px] text-Black100 md:text-[36px]"
                onClick={validate}
            >
                Where do you need legal help?
            </h2>

            {/* <div className=""> */}
            <CityAutoComplete
                setCity={setCity}
                setState={setState}
                isValid={city !== '' && state !== ''}
                city={city}
                state={state}
                locationError={locationError}
                setLocationError={setLocationError}
                className="mx-auto mb-[100px] lg:w-[400px]"
            />
            {/* </div> */}

            <QuestionNav
                step={step}
                funnelSize={funnelSize}
                returnToLanding={returnToLanding}
                backStep={backStep}
                forwardStep={() => {
                    forwardStep(validate)
                }}
                directToFunnel={directToFunnel}
            />
        </>
    )
}

export default FunnelCitySelect
