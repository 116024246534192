import React from 'react'
import Illustration from '../../../assets/images/Illustration.png'
import { getStateNameFromUrl } from '@firmleads/utils'
import ButtonCTA from '../../../components/ButtonCTA'
import { useLocation } from 'react-router-dom'
import { handlebarsOut } from '../../../utils'

interface FreeConsultationProps {
    content: any
}

const FreeConsultation: React.FC<FreeConsultationProps> = ({ content }) => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const isFreeConsultation =
        searchParams.get('isFreeConsultation') !== 'false'
    return (
        <div className="mb-[300px] border border-transparent bg-BgColor sm:mb-[372px] lg:mb-[220px]">
            <div className="-mb-[300px] px-4 sm:-mb-[372px] md:px-5 lg:-mb-[220px] lg:px-20">
                <div className=" mx-auto max-w-[570px] justify-between rounded-[16px] bg-MainColor px-5 pt-[30px] text-[18px] text-Neutral000 lg:flex lg:max-w-[1170px] lg:px-[50px] lg:pt-[60px]">
                    <div className="mx-auto max-w-[470px] pb-[60px]">
                        {isFreeConsultation ? (
                            <>
                                <h2
                                    className="font-Leitura  mb-[20px] text-[24px] md:text-[36px]"
                                    dangerouslySetInnerHTML={{
                                        __html: handlebarsOut(content.headline),
                                    }}
                                >
                                    {/*Your First <br />
                                    Consultation is Free*/}
                                </h2>
                                <p
                                    className=" font-Arial mx-auto  text-[16px] md:text-[18px]"
                                    dangerouslySetInnerHTML={{
                                        __html: handlebarsOut(
                                            content.description
                                        ),
                                    }}
                                >
                                    {/*It would be a mistake to continue in legal
                                    limbo without first getting guidance on your
                                    options. Schedule a no obligation free
                                    consultation. A{' '}
                                    {getStateNameFromUrl(
                                        window.location.hostname
                                    )}{' '}
                                    lawyer can discuss your options and chart
                                    the optimal way ahead. Don't walk it alone -
                                    get informed guidance first.*/}
                                </p>
                            </>
                        ) : (
                            <>
                                <h2 className="font-Leitura  mb-[20px] text-[24px] md:text-[36px]">
                                    Gain Clarity <br />
                                    On Your Legal Matter
                                </h2>
                                <p className=" font-Arial mx-auto  text-[16px] md:text-[18px]">
                                    Consult an experienced{' '}
                                    {getStateNameFromUrl(
                                        window.location.hostname
                                    )}{' '}
                                    lawyer to understand your legal options.
                                    Schedule a comprehensive guidance session
                                    tailored to your specific situation. Don't
                                    go it alone, seek expert advice.
                                </p>
                            </>
                        )}
                        <a href="#form">
                            <ButtonCTA className="mt-[30px] flex justify-center text-[18px]" />
                        </a>
                    </div>
                    <div className="flex-col, flex items-end">
                        <img
                            className="lg:mt-full h-auto w-full max-w-[550px]"
                            src={Illustration}
                            alt="people working"
                            loading={'lazy'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FreeConsultation
