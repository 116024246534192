import React, { useState } from 'react'
interface AttorneyProps {
    attorney: {
        id: number
        name: string
        firmName: string
        credentials: string[]
        lawType: string[]
        picture: string
        bio: string
        memberships: string[]
    }
}

const Attorney: React.FC<AttorneyProps> = ({ attorney }) => {
    const { name, credentials, lawType, picture, bio, memberships } = attorney

    const [isFlipped, setIsFlipped] = useState(false)

    const handleReadMore = () => {
        setIsFlipped(!isFlipped)
    }

    return (
        <div className="unselectable h-full min-h-[700px]  px-[10px]">
            <div className={`card ${isFlipped ? 'rotateY180' : ''}`}>
                <div
                    className={`front inline-flex h-full w-full flex-col  justify-between rounded-lg bg-[#fff] p-5  `}
                >
                    <div>
                        <img
                            className="py-auto mx-auto h-[160px] w-[160px] rounded-full md:h-[240px] md:w-[240px]"
                            src={picture}
                            alt="review person"
                            loading={'lazy'}
                        />
                        <div className="mb-[10px] flex flex-col items-start px-5">
                            <p className="font-Leitura mt-6 text-[22px] text-Black100 md:text-[24px]">
                                {name}
                            </p>
                            {/* <p className="font-Arial text-[16px] text-Black400 md:text-[18px]">
                                {firmName}
                            </p> */}
                            <p className="font-arial mb-2 mt-8 text-[14px] font-bold text-MainColor">
                                CREDENTIALS
                            </p>
                            {credentials &&
                                credentials.length > 0 &&
                                credentials.map((credential, index) => {
                                    return (
                                        <p
                                            key={index}
                                            className="font-Arial text-[14px] text-Black400"
                                        >
                                            {credential}
                                        </p>
                                    )
                                })}
                            <p className="font-arial mb-2 mt-8 text-[14px] font-bold text-MainColor">
                                LAW AREAS
                            </p>
                            {lawType &&
                                lawType.length > 0 &&
                                lawType.map((type, index) => {
                                    return (
                                        <p
                                            key={index}
                                            className="font-Arial text-center text-[14px] text-Black400"
                                        >
                                            {type}
                                        </p>
                                    )
                                })}
                        </div>
                    </div>

                    <button
                        onClick={handleReadMore}
                        className="font-Arial w-full rounded-lg bg-BgColor px-[20px] py-[10px] text-[16px] font-bold text-MainColor hover:bg-MainColor hover:text-white"
                    >
                        Read More
                    </button>
                </div>
                <div className="back inline-flex h-full w-full flex-col items-center justify-between rounded-lg  bg-[#fff] p-5">
                    <div className=" max-mb-12 flex flex-col ">
                        <p className="font-Leitura mt-6 text-center text-[22px] text-Black100 md:text-[24px]">
                            {name}
                        </p>
                        {/* <p className="font-Arial text-center text-[16px] text-Black400 md:text-[18px]">
                            {firmName}
                        </p> */}
                        {memberships && memberships.length > 0 && (
                            <p className="font-arial mb-2 mt-8 text-center text-[14px] font-bold text-MainColor">
                                ASSOC./MEMBERSHIP
                            </p>
                        )}
                        {memberships &&
                            memberships.length > 0 &&
                            memberships.map((membership, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="font-Arial text-[14px] text-Black400"
                                    >
                                        {membership}
                                    </p>
                                )
                            })}
                        <p className="font-arial mb-2 mt-8 text-center text-[14px] font-bold text-MainColor">
                            ABOUT
                        </p>

                        <p className="font-Arial text-start text-[14px] text-Black400">
                            {bio}
                        </p>
                    </div>

                    <button
                        onClick={handleReadMore}
                        className="font-Arial w-full rounded-lg bg-BgColor px-[20px] py-[10px] text-[16px] font-bold text-MainColor hover:bg-MainColor hover:text-white"
                    >
                        Read Less
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Attorney
