import { Link, useLocation } from 'react-router-dom'
import SearchingPic from '../../assets/images/NoFoundPic.svg'
// import { useEffect } from 'react'

const Searching = () => {
    const location = useLocation()

    const { state } = location

    // useEffect(() => {
    //     if (!state) return
    //     window.dataLayer = window.dataLayer || []
    //     window.dataLayer.push({
    //         event: 'conversion',
    //         // conversionValue: 50,
    //         // leadId: 'testIdFromColby',
    //         conversionValue: state.conversionValue ?? 0,
    //         leadId: state.leadId ?? 'no_lead',
    //     })
    // }, [])

    // <script>!function(e,t,a,n,g){e[n]=e[n]||[],e[n].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id=GTM-KZKX9P9T",m.parentNode.insertBefore(r,m)}(window,document,"script","dataLayer")</script>

    return (
        <div
            className="items-center justify-center p-5 lg:pb-[200px]"
            style={{ minHeight: 'calc(100vh - 453px)' }}
        >
            <img
                src={SearchingPic}
                alt="No Lawyer Found"
                className="mx-auto my-[50px] h-[227px] lg:my-[100px] lg:h-[315px]"
            />

            <h1 className="font-Leitura mb-6 text-center text-4xl">
                Need To Circle Back
            </h1>

            <div className="font-Arial mx-auto max-w-[400px] text-center text-Black400">
                <p>
                    We're sorry, we don't currently have a lawyer specializing
                    in {state ? state.lawArea : 'family'} law in{' '}
                    {state ? state.state : 'Hawaii'}.{' '}
                    <strong>
                        <em>
                            However, we are often able to connect clients with a
                            suitable lawyer within 24 hours.
                        </em>
                    </strong>{' '}
                    If we establish a partnership with a lawyer who can assist
                    you, we will promptly notify you.
                </p>
                <Link to="/">
                    <button className=" mt-[30px] h-[60px] w-full rounded-lg bg-MainColor px-[29px] py-[16px] text-[16px] font-bold text-white lg:w-[320px]">
                        Go Back to Home
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default Searching
