import React from 'react'
import Stamp from '../../../assets/images/Stamp.png'
import HighFive from '../../../assets/images/HighFive.png'
import Compass from '../../../assets/images/Compass.png'

interface WhyLawyerProps {
    lawArea: string
    content: any
}

const WhyLawyer: React.FC<WhyLawyerProps> = ({ lawArea, content }) => {
    return (
        <div className="px-4 py-[50px] md:px-5 lg:py-[100px]">
            <div className="mx-auto max-w-[1170px]">
                <div>
                    <h2 className="font-Leitura mx-auto max-w-[770px] text-center text-[24px] md:text-[36px]">
                        {content.overview.head}
                    </h2>
                    <p className="font-Arial mx-auto mt-[20px] max-w-[770px] text-center text-[16px] text-Black400 md:text-[18px]">
                        {content.overview.body}
                    </p>
                </div>
                <div className="mt-[30px] lg:mt-[50px] lg:flex">
                    <div className="mx-auto my-auto max-w-[550px] lg:mr-[30px] lg:w-1/2-30px">
                        <h3 className="font-Leitura text-[20px] md:text-[24px]">
                            {content.card1.head}
                        </h3>
                        <p className="font-Arial mx-auto mt-[10px] text-[16px] text-Black400 md:text-[18px]">
                            {content.card1.body}
                        </p>
                    </div>
                    <img
                        className="mx-auto mt-[20px] rounded-[16px] lg:mt-0 lg:w-1/2-30px"
                        src={Stamp}
                        alt="stamp"
                        loading={'lazy'}
                    />
                </div>
                <div className="mt-[30px] flex flex-col-reverse lg:mt-[50px] lg:flex-row">
                    <img
                        className="mx-auto mt-[20px] block rounded-[16px] lg:mr-[30px] lg:mt-0 lg:w-1/2-30px"
                        src={HighFive}
                        alt="high five"
                        loading={'lazy'}
                    />
                    <div className="mx-auto my-auto max-w-[550px] lg:w-1/2-30px">
                        <h3 className="font-Leitura text-[20px] md:text-[24px]">
                            {content.card2.head}
                        </h3>
                        <p className="font-Arial mt-[10px] text-[16px] text-Black400 md:text-[18px]">
                            {content.card2.body}
                        </p>
                    </div>
                </div>
                <div className="mt-[30px] lg:mt-[50px] lg:flex">
                    <div className="mx-auto my-auto max-w-[550px] lg:mr-[30px] lg:w-1/2-30px">
                        <h3 className="font-Leitura text-[20px] md:text-[24px]">
                            {content.card3.head}
                        </h3>
                        <p className="font-Arial mt-[10px] text-[16px] text-Black400 md:text-[18px]">
                            {content.card3.body}
                        </p>
                    </div>
                    <img
                        className="mx-auto mt-[20px] rounded-[16px] lg:mt-0 lg:w-1/2-30px"
                        src={Compass}
                        alt="compass"
                        loading={'lazy'}
                    />
                </div>
            </div>
        </div>
    )
}

export default WhyLawyer
