import { Config as GeneratedTypes } from '@firmleads/types'
import qs from 'qs'
import axios from 'axios'
import type { DeepPartial, MarkOptional } from 'ts-essentials'
import moment, { Moment } from 'moment'

class PayloadSDK<U extends keyof GeneratedTypes['collections']> {
    public userCollection: U
    private url: string
    private apiKey: string

    constructor(userCollection: U, url: string, apiKey?: string) {
        this.userCollection = userCollection
        this.url = url
        this.apiKey = apiKey || ''
    }

    /**
     * @description Performs count operation
     * @param options
     * @returns count of documents satisfying query
     */
    count = async <T extends keyof GeneratedTypes['collections']>(
        options: CountOptions<T>,
        token?:string
    ): Promise<number> => {
        const collection = options.collection
        delete (options as Partial<typeof options>).collection
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get<{ totalDocs: number }>(
                `${this.url}/api/${String(collection)}${stringifiedQuery}`,
                header,
            )

            return response.data.totalDocs
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`count: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description Performs create operation
     * @param options
     * @returns created document
     */
    create = async <T extends keyof GeneratedTypes['collections']>(
        options: CreateOptions<T>,
        fileUpload?: boolean,
        token?: string
    ): Promise<GeneratedTypes['collections'][T]> => {
        const collection = options.collection
        const data = options.data
        delete (options as Partial<typeof options>).collection
        delete (options as Partial<typeof options>).data
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        const config: any = {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            },
        }
        if (fileUpload) {
            config.headers['Content-Type'] =
                'multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM'
        } else {
            config.headers['Content-Type'] = 'application/json'
        }

        if(token) {
            config.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.post(
                `${this.url}/api/${String(collection)}${stringifiedQuery}`,
                JSON.stringify(data),
                config,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`create: API request failed: ${error.message}`)
            }
            throw error
        }
    }
    /**
     * @description Find documents with criteria
     * @param options
     * @returns documents satisfying query
     */
    find = async <T extends keyof GeneratedTypes['collections']>(
        options: FindOptions<T>,
        token?: string
    ): Promise<PaginatedDocs<GeneratedTypes['collections'][T]>> => {
        const collection = options.collection
        delete (options as Partial<typeof options>).collection
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }


        try {
            const response = await axios.get(
                `${this.url}/api/${String(collection)}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`find: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    findByID = async <T extends keyof GeneratedTypes['collections']>(
        options: FindByIDOptions<T>,
        token?: string
    ): Promise<GeneratedTypes['collections'][T]> => {
        const id = options.id
        const collection = options.collection
        delete (options as Partial<typeof options>).collection
        delete (options as Partial<typeof options>).id

        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(
                `${this.url}/api/${String(collection)}/${id}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`findByID: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    findGlobal = async <T extends keyof GeneratedTypes['globals']>(
        options: FindGlobalOptions<T>,
        token?: string
    ): Promise<GeneratedTypes['globals'][T]> => {
        const slug = options.slug
        delete (options as Partial<typeof options>).slug
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(
                `${this.url}/api/globals/${String(slug)}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`findGlobal: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description Find global version by ID
     * @param options
     * @returns global version with specified ID
     */
    findGlobalVersionByID = async <T extends keyof GeneratedTypes['globals']>(
        options: FindGlobalVersionByIDOptions<T>,
        token?: string
    ): Promise<TypeWithVersion<GeneratedTypes['globals'][T]>> => {
        const slug = options.slug
        const id = options.id
        delete (options as Partial<typeof options>).slug
        delete (options as Partial<typeof options>).id
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(
                `${this.url}/api/globals/${String(slug)}/versions/${id}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`findGlobalVersionsByID: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description Find global versions with criteria
     * @param options
     * @returns versions satisfying query
     */
    findGlobalVersions = async <T extends keyof GeneratedTypes['globals']>(
        options: FindGlobalVersionsOptions<T>,
        token?: string
    ): Promise<PaginatedDocs<TypeWithVersion<GeneratedTypes['globals'][T]>>> => {
        const slug = options.slug
        delete (options as Partial<typeof options>).slug
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(
                `${this.url}/api/globals/${String(slug)}/versions${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`findGlobalVersions: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description Find version by ID
     * @param options
     * @returns version with specified ID
     */
    findVersionByID = async <T extends keyof GeneratedTypes['collections']>(
        options: FindVersionByIDOptions<T>,
        token?: string
    ): Promise<TypeWithVersion<GeneratedTypes['collections'][T]>> => {
        const id = options.id
        const collection = options.collection
        delete (options as Partial<typeof options>).collection
        delete (options as Partial<typeof options>).id

        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(
                `${this.url}/api/${String(collection)}/${id}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`findVersionsByID: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description Find versions with criteria
     * @param options
     * @returns versions satisfying query
     */
    findVersions = async <T extends keyof GeneratedTypes['collections']>(
        options: FindOptions<T>,
        token?: string
    ): Promise<PaginatedDocs<TypeWithVersion<GeneratedTypes['collections'][T]>>> => {
        const collection = options.collection
        delete (options as Partial<typeof options>).collection
        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(
                `${this.url}/api/${String(collection)}/versions${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`findVersions: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    forgotPassword = async (
        email: string,
        expiration?: number,
        disableEmail?: boolean,
        token?: string
    ): Promise<ForgotPasswordResult> => {
        let stringifiedQuery: string = ''
        if(expiration || disableEmail) {
            stringifiedQuery = qs.stringify(
                { expiration, disableEmail },
                { addQueryPrefix: true },
            )
        }

        let header: any = { 
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.post(
                `${this.url}/api/${this.userCollection}/forgot-password${stringifiedQuery}`,
                JSON.stringify({ email }),
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`forgotPassword: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    login = async (
        email: string,
        password: string,
        depth?: number,
    ): Promise<LoginResult & { user: GeneratedTypes['collections'][U] }> => {
        const stringifiedQuery = qs.stringify({ depth }, { addQueryPrefix: true })
        

        try {
            const response = await axios.post(
                `${this.url}/api/${this.userCollection}/login${stringifiedQuery}`,
                { email, password },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
                    },
                },
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`login: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    // /**
    //  * @description
    //  * @param options
    //  * @returns
    //  */

    resetPassword = async (options: ResetPasswordOptions): Promise<ResetPasswordResult> => {
        const data = options.data
        delete (options as Partial<typeof options>).data

        try {
            const response = await axios.post(
                `${this.url}/api/${this.userCollection}/reset-password`,
                JSON.stringify(data),
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
                    },
                },
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`resetPassword: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    signup = async (
        data: MarkOptional<GeneratedTypes['collections'][U], 'createdAt' | 'id' | 'updatedAt'>,
    ): Promise<GeneratedTypes['collections'][U]> => {
        const createOptions = {
            collection: this.userCollection,
            data,
            depth: 2,
        }
        return this.create(createOptions)
    }

    logout = async (token?:string): Promise<LogoutResult> => {
        let header: any = { 
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        } 
        try {
            const response = await axios.post(
                `${this.url}/api/${this.userCollection}/logout`,
                {},
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`logout: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    refreshToken = async (): Promise<
        RefreshTokenResult & { user: GeneratedTypes['collections'][U] }
    > => {
        try {
            const response = await axios.post(
                `${this.url}/api/${this.userCollection}/refresh-token`,
                {},
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`logout: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    currentUser = async (token?: string): Promise<
        CurrentUserResult & { collection: U; user: GeneratedTypes['collections'][U] }
    > => 
    {
        let header: any = { 
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.get(`${this.url}/api/${this.userCollection}/me`, header)
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`logout: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    // unlock = async <T extends keyof TGeneratedTypes['collections']>(
    //     options: UnlockOptions<T>
    // ): Promise<boolean> => {
    //     const { unlock } = localOperations.auth
    //     return unlock(this, options)
    // }

    // updateGlobal = async <T extends keyof TGeneratedTypes['globals']>(
    //     options: UpdateGlobalOptions<T>
    // ): Promise<TGeneratedTypes['globals'][T]> => {
    //     const { update } = localGlobalOperations
    //     return update<T>(this, options)
    // }

    // verifyEmail = async <T extends keyof TGeneratedTypes['collections']>(
    //     options: VerifyEmailOptions<T>
    // ): Promise<boolean> => {
    //     const { verifyEmail } = localOperations.auth
    //     return verifyEmail(this, options)
    // }

    delete = async <T extends keyof GeneratedTypes['collections']>(
        options: DeleteOptions<T>,
        token?: string
    ): Promise<BulkOperationResult<T> | GeneratedTypes['collections'][T]> => {
        const collection = options.collection
        delete (options as Partial<typeof options>).collection

        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.delete(
                `${this.url}/api/${String(collection)}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`delete: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description delete one or more documents
     * @param options
     * @returns Updated document(s)
     */
    deleteByID = async <T extends keyof GeneratedTypes['collections']>(
        options: DeleteByIDOptions<T>,
        token?:string
    ): Promise<GeneratedTypes['collections'][T]> => {
        const id = options.id
        const collection = options.collection
        delete (options as Partial<typeof options>).collection
        delete (options as Partial<typeof options>).id

        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })

        let header: any = { 
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.delete(
                `${this.url}/api/${String(collection)}/${id}${stringifiedQuery}`,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`deleteByID: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    /**
     * @description Update one or more documents
     * @param options
     * @returns Updated document(s)
     */
    updateByID = async <T extends keyof GeneratedTypes['collections']>(
        options: UpdateByIDOptions<T>,
        token?:string
    ): Promise<GeneratedTypes['collections'][T]> => {
        const id = options.id
        const collection = options.collection
        const data = options.data
        delete (options as Partial<typeof options>).collection
        delete (options as Partial<typeof options>).id
        delete (options as Partial<typeof options>).data

        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })
        const stringifiedBody = JSON.stringify(data)

        let header: any = { 
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.patch(
                `${this.url}/api/${String(collection)}/${id}${stringifiedQuery}`,
                stringifiedBody,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`updateByID: API request failed: ${error.message}`)
            }
            throw error
        }
    }

    update = async <T extends keyof GeneratedTypes['collections']>(
        options: UpdateOptions<T>,
        token?: string
    ): Promise<BulkOperationResult<T> | GeneratedTypes['collections'][T]> => {
        const collection = options.collection
        const data = options.data

        delete (options as Partial<typeof options>).collection
        delete (options as Partial<typeof options>).data

        const stringifiedQuery = qs.stringify(options, { addQueryPrefix: true })
        const stringifiedBody = JSON.stringify(data)

        let header: any = { 
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${this.userCollection} API-Key ${this.apiKey}`,
            }
        }

        if(token) {
            header.headers['Cookie'] = `payload-token=${token};`
        }

        try {
            const response = await axios.patch(
                `${this.url}/api/${String(collection)}${stringifiedQuery}`,
                stringifiedBody,
                header,
            )
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(`update: API request failed: ${error.message}`)
            }
            throw error
        }
    }
}

export default PayloadSDK

export function timestamp(date?: Moment | null): string {
    if (!date) {
        return moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    }

    return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}
export type CountOptions<T extends keyof GeneratedTypes['collections']> = {
    collection: T
    locale?: string
    where?: Where
}

export type CreateOptions<TSlug extends keyof GeneratedTypes['collections']> = {
    autosave?: boolean
    collection: TSlug
    data: MarkOptional<GeneratedTypes['collections'][TSlug], 'createdAt' | 'id' | 'updatedAt'>
    depth?: number
    draft?: boolean
}

export type FindOptions<T extends keyof GeneratedTypes['collections']> = {
    collection: T
    depth?: number
    draft?: boolean
    fallbackLocale?: string
    limit?: number
    locale?: string
    page?: number
    sort?: string
    where?: Where
}

export type FindByIDOptions<T extends keyof GeneratedTypes['collections']> = {
    collection: T
    depth?: number
    draft?: boolean
    fallbackLocale?: string
    id: number | string
    locale?: string
}

export type FindGlobalOptions<T extends keyof GeneratedTypes['globals']> = {
    depth?: number
    draft?: boolean
    fallbackLocale?: string
    locale?: string
    slug: T
}

export type FindGlobalVersionByIDOptions<T extends keyof GeneratedTypes['globals']> = {
    depth?: number
    disableErrors?: boolean
    fallbackLocale?: string
    id: string
    locale?: string
    slug: T
}

export type FindGlobalVersionsOptions<T extends keyof GeneratedTypes['globals']> = {
    depth?: number
    fallbackLocale?: string
    limit?: number
    locale?: string
    page?: number
    slug: T
    sort?: string
    where?: Where
}

export type FindVersionByIDOptions<T extends keyof GeneratedTypes['collections']> = {
    collection: T
    depth?: number
    draft?: boolean
    fallbackLocale?: string
    id: string
    locale?: string
}

export type ForgotPasswordOptions = {
    data: {
        email: string
    }
    disableEmail?: boolean
    expiration?: number
}

export type LoginOptions = {
    data: {
        email: string
        password: string
    }
    depth?: number
}

export type LoginResult = {
    exp?: number
    token?: string
}

export type ResetPasswordResult = {
    token?: string
}

export type LogoutResult = {
    message: string
}

export type CurrentUserResult = {
    token: string
    exp: number
}

export type ResetPasswordOptions = {
    data: {
        token: string
        password: string
    }
}

export type RefreshTokenResult = {
    exp: number
    refreshedToken: string
    strategy?: string
    message: string
}

export type DeleteBaseOptions<T extends keyof GeneratedTypes['collections']> = {
    collection: T
    depth?: number
    fallbackLocale?: string
    locale?: string
}

export type DeleteByIDOptions<T extends keyof GeneratedTypes['collections']> =
    DeleteBaseOptions<T> & {
        id: number | string
        where?: never
    }

export type DeleteOptions<T extends keyof GeneratedTypes['collections']> = DeleteBaseOptions<T> & {
    where: Where
}

export type UpdateBaseOptions<TSlug extends keyof GeneratedTypes['collections']> = {
    autosave?: boolean
    collection: TSlug
    /**
     * context, which will then be passed to req.context, which can be read by hooks
     */

    data: DeepPartial<GeneratedTypes['collections'][TSlug]>
    depth?: number
    draft?: boolean
    fallbackLocale?: string
    locale?: string
    overrideAccess?: boolean
    overwriteExistingFiles?: boolean
}

export type UpdateByIDOptions<TSlug extends keyof GeneratedTypes['collections']> =
    UpdateBaseOptions<TSlug> & {
        id: number | string
    }

export type UpdateOptions<TSlug extends keyof GeneratedTypes['collections']> =
    UpdateBaseOptions<TSlug> & {
        where: Where
    }

export type PaginatedDocs<T = any> = {
    docs: T[]
    hasNextPage: boolean
    hasPrevPage: boolean
    limit: number
    nextPage?: null | number | undefined
    page?: number
    pagingCounter: number
    prevPage?: null | number | undefined
    totalDocs: number
    totalPages: number
}

export type TypeWithVersion<T> = {
    createdAt: string
    id: string
    parent: number | string
    updatedAt: string
    version: T
}

export const validOperators = [
    'equals',
    'contains',
    'not_equals',
    'in',
    'all',
    'not_in',
    'exists',
    'greater_than',
    'greater_than_equal',
    'less_than',
    'less_than_equal',
    'like',
    'within',
    'intersects',
    'near',
] as const

export type Operator = (typeof validOperators)[number]

export type WhereField = {
    [key in Operator]?: unknown
}

export type Where = {
    [key: string]: Where[] | WhereField | undefined
    and?: Where[]
    or?: Where[]
}

export type ForgotPasswordResult = string

export type BulkOperationResult<TSlug extends keyof GeneratedTypes['collections']> = {
    docs: GeneratedTypes['collections'][TSlug][]
    errors: {
        id: GeneratedTypes['collections'][TSlug]['id']
        message: string
    }[]
}
